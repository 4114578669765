/**
 * Provides a context and mechanism for managing toast notifications throughout the application.
 *
 * This context allows components to display toast notifications by calling `addToast()`. Toasts are automatically removed after 5 seconds
 * or can be manually closed by the user.
 *
 * @module ToastProvider
 */
import React, { createContext, useContext, useState, ReactNode } from 'react';
import ReactDOM from 'react-dom';
import { v4 as uuid } from 'uuid';
import Toast from '../components/common/Toast';
import styles from 'styles/provider/ToastProvider.module.sass';
import ToastData from 'interface/ToastData';

/**
 * Interface for the context's properties.
 *
 * @interface ToastContextProps
 * @property {Function} addToast - Function to add a new toast notification.
 * Accepts partial `ToastData` properties to create the toast.
 */
interface ToastContextProps {
  addToast: (data: Partial<ToastData>) => void;
}

/**
 * Creates the context for managing toast notifications.
 */
const ToastContext = createContext<ToastContextProps | undefined>(undefined);

/**
 * Hook to use the `ToastContext`.
 *
 * @function
 * @throws {Error} If used outside the `ToastProvider`.
 * @returns {ToastContextProps} The context's functions for managing toasts.
 */
export const useToast = (): ToastContextProps => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error('useToast must be used within a ToastProvider');
  }
  return context;
};

/**
 * Props for the `ToastProvider` component.
 *
 * @interface ToastProviderProps
 * @property {ReactNode} children - The child components wrapped by the provider.
 */
interface ToastProviderProps {
  children: ReactNode;
}

/**
 * Provides a context and portal for managing toast notifications.
 *
 * @component
 * @example
 * ```tsx
 * import { ToastProvider, useToast } from 'provider/ToastProvider';
 *
 * const App = () => (
 *   <ToastProvider>
 *     <MainComponent />
 *   </ToastProvider>
 * );
 *
 * const MainComponent = () => {
 *   const { addToast } = useToast();
 *
 *   return (
 *     <button
 *       onClick={() => addToast({ message: 'Hello, zerone!', type: 'success' })}
 *     >
 *       Show Toast
 *     </button>
 *   );
 * };
 * ```
 * @param {ToastProviderProps} props - The props for the `ToastProvider`.
 * @returns {JSX.Element} The provider and portal for toasts.
 */
export const ToastProvider: React.FC<ToastProviderProps> = ({ children }) => {
  const [toasts, setToasts] = useState<ToastData[]>([]);

  /**
   * Adds a new toast notification.
   *
   * @function
   * @param {Partial<ToastData>} data - Partial properties for the new toast.
   * If `message` is not provided, the toast is not added.
   */
  const addToast = (data: Partial<ToastData>) => {
    const { message, type = 'info', time = new Date(), link, onClose } = data;

    if (!message) {
      console.warn('Toast must have a message.');
      return;
    }

    setToasts((prev) => {
        const exists = prev.some((toast) => toast.message === message && toast.type === type);
        if (exists) return prev;
    
        const newToast: ToastData = {
          id: uuid(),
          message,
          type,
          time,
          link: link || undefined,
          onClose: onClose || (() => {})
        };
    
        return [...prev, newToast];
      });

    // Automatically remove toast after 5 seconds
    setTimeout(() => {
      setToasts((prev) => prev.filter((toast) => toast.message !== message || toast.type !== type));
    }, 5000);
  };

  /**
   * Removes a toast by its ID.
   *
   * @function
   * @param {string} id - The ID of the toast to remove.
   */
  const removeToast = (id: string) => {
    setToasts((prev) => prev.filter((toast) => toast.id !== id));
  };

  return (
    <ToastContext.Provider value={{ addToast }}>
      {children}
      {ReactDOM.createPortal(
        <div className={styles['toast-container']}>
          {toasts.map((toast) => (
            <Toast
              key={toast.id}
              id={toast.id}
              message={toast.message}
              type={toast.type}
              time={toast.time}
              link={toast.link}
              onClose={() => {
                toast.onClose?.();
                removeToast(toast.id);
              }}
            />
          ))}
        </div>,
        document.body
      )}
    </ToastContext.Provider>
  );
};

export default ToastProvider;
