import { logError } from "services/logService/errorLogger";
import { SignedApiResponse } from "./ApiTypes";
import { ApiResponse } from "interface/ApiResponse";
import getJwtToken from "utils/GetJwtToken";

const API_BASE_URL = process.env.REACT_APP_ENVIRONMENT === 'production' 
    ? process.env.REACT_APP_PROD_API_URL 
    : process.env.REACT_APP_DEV_API_URL

const APP_API_KEY = process.env.REACT_APP_ENVIRONMENT === 'production'
    ? process.env.REACT_APP_PROD_API_KEY
    : process.env.REACT_APP_DEV_API_KEY

/**
 * Fetches a CSRF token from the server.
 * 
 * @async
 * @param {string} TAG - A tag used for logging purposes.
 * @returns {Promise<string>} The CSRF token retrieved from the server or a default token.
 * @throws Will throw an error if the token cannot be fetched.
 */
export const getCsrfToken = async (TAG: string): Promise<string> => {
    try {
        const response = await fetch(`${API_BASE_URL}/api/csrf-token`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${APP_API_KEY || ''}`
            }
        })
        
        if (response.ok) {
            const data: SignedApiResponse<{ csrfToken: string }> = await response.json();
            return data.response.data?.csrfToken || 'CSRF_TOKEN_FROM_STORAGE_OR_API';
        }
        throw new Error('Failed to fetch CSRF Token')
    } catch (error) {
        logError(
            'Error retrieving CSRF Token',
            { error: error instanceof Error ? error.message : 'Unknown error' },
            TAG
        )
        throw error
    }
};

/**
 * Generates default headers for API requests, including CSRF token and API key.
 * 
 * @async
 * @param {string} TAG - A tag used for logging purposes.
 * @param {string | null} signature - Optional HMAC signature for request validation.
 * @returns {Promise<HeadersInit>} The headers to be used for API requests.
 * @throws Will throw an error if headers cannot be generated.
 */
export const getDefaultHeaders = async (TAG: string, signature: string | null): Promise<HeadersInit> => {
    try {
        const csrfToken = await getCsrfToken(TAG);
        
        const defaultHeaders: Record<string, string> = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${APP_API_KEY || ''}`,
            'x-csrf-token': csrfToken || ''
        };

        if (signature) {
            defaultHeaders['X-Signature'] = signature;
        }

        // Check if auth token is stored
        const authToken = getJwtToken()
        if (authToken) {
            defaultHeaders['x-jwt-token'] = authToken
        }

        return defaultHeaders
    } catch (error) {
        logError(
            'Error generating headers',
            { error: error instanceof Error ? error.message : 'Unknown error' },
            TAG
        )
        throw error
    }
}


/**
 * Handles the response from a fetch request.
 * 
 * @template T
 * @async
 * @param {string} TAG - A tag used for logging purposes.
 * @param {Response} response - The fetch response object.
 * @returns {Promise<ApiResponse<T>>} The parsed response data or an error response.
 */
export const handleFetchResponse = async function <T>(
    TAG: string,
    response: Response
): Promise<ApiResponse<T>> {
    try {
        // Read the response body once
        const jsonResponse: ApiResponse<T> = await response.json();

        if (!response.ok) {
            console.log(jsonResponse)
            const res: ApiResponse<T> = {
                status: "error",
                message: jsonResponse.message,
                data: null,
                error: jsonResponse.error || 'Unknown error',
                timestamp: jsonResponse.timestamp
            }

            return res;
        }

        return {
            status: "success",
            message: response.statusText || "Request successful",
            data: jsonResponse as T,
            timestamp: new Date().toISOString(),
        };
    } catch (error) {
        const errorMessage = error instanceof Error ? error.message : "Unknown error";
        const errorDetails: ApiResponse<T> = {
            status: "error",
            message: "Error occurred during fetch response handling",
            error: errorMessage,
            data: null,
            timestamp: new Date().toISOString(),
        };
        logError("Error occurred while handling fetch response", errorDetails, TAG);
        return errorDetails
    }
}



/**
 * Handles errors that occur during fetch requests.
 * 
 * @template T
 * @param {string} TAG - A tag used for logging purposes.
 * @param {unknown} error - The error object thrown during the fetch request.
 * @returns {SignedApiResponse<T>} An error response containing the status and error message.
 */
export const handleError = function <T>(TAG: string, error: unknown): SignedApiResponse<T> {
    const errorMessage =
        error instanceof TypeError
            ? 'Network error or request was aborted'
            : error instanceof Error
            ? error.message
            : 'An unexpected error occurred';

    const errorDetails: SignedApiResponse<T> = {
        response: {
            status: 'error',
            message: 'Error occurred during fetch request',
            error: errorMessage,
            timestamp: new Date().toISOString(),
        },
        signature: null,
    };

    logError(
        'Error occurred while handling fetch request',
        { error: errorMessage, TAG },
        TAG
    )

    return errorDetails;
}

