const stripeErrorMessages: { [key: string]: string } = {
    network: 'Network error detected. Please check your connection and try again.',
    generic_decline: 'Payment was declined. Please try again or use a different card.',
    insufficient_funds: 'Your card has insufficient funds. Please use a different card or add funds.',
    lost_card: 'This card has been reported lost. Please use a different card.',
    stolen_card: 'This card has been reported stolen. Please contact your bank or use a different card.',
    expired_card: 'This card has expired. Please use a different card.',
    incorrect_cvc: 'The card CVC is incorrect. Please double-check and try again.',
    processing_error: 'There was a processing error. Please try again later or use a different card.',
    incorrect_number: 'The card number is incorrect. Please double-check and try again.',
    velocity_limit: 'You have exceeded the payment limit for this card. Please use a different card.',
    card: 'Your card was declined. Verify card details or use a different payment method.',
    timeout: 'Payment process timed out. Please try again.'
}

export default stripeErrorMessages;