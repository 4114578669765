const getDefaultCountryFromLocale = (): string => {
    const locale = navigator.language || (navigator.languages && navigator.languages[0]) || 'en-US'

    if (locale.includes('-')) {
        const parts = locale.split('-')
        if (parts.length > 1) {
            return parts[1]?.toUpperCase() || 'US'
        }
    }

    return 'US'
}

export default getDefaultCountryFromLocale;