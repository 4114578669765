const storeJwtToken = (token: string, isSecure: boolean = true): void => {
    if (isSecure) {
        // Store token in HttpOnly cookies (production use case)
        document.cookie = `auth_token=${token}; path=/; Secure; SameSite=Strict; HttpOnly`;
    } else {
        // For development environments or testing purposes
        sessionStorage.setItem('auth_token', token);
    }
    window.dispatchEvent(new Event('authChange'))
}

export default storeJwtToken