/* eslint-disable react/no-unescaped-entities */
import React, { useState } from 'react';
import styles from 'styles/components/WorkspaceSection.module.sass';
import PrimaryButton from 'components/common/PrimaryButton';
import comingSoonImage from 'assets/images/png/coming-soon-btn.png';

const WorkspaceSection: React.FC = () => {
  const [email, setEmail] = useState('');

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    // Handle form submission logic here
    console.log('Email submitted:', email);
  };

  return (
    <section className={styles.comingSoonSection}>
      <div className={styles.container}>
        <div className={styles.content}>
          <h1 className={styles.headline}>
            <span className={styles.workspace}>WORKSPACE</span>
            <span className={styles.comingSoon}>Coming Soon</span>
          </h1>
          <p className={styles.description}>
            Organize, analyze and visualize your data with our
            <br />
            AI-Powered comingSoon.
            <br />
            Be the first to know when it's ready!
          </p>
          <form onSubmit={handleSubmit} className={styles.form}>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="youremail@example.com"
              className={styles.emailInput}
              required
            />
        
            <PrimaryButton
              text="Join the waitlist!"
              primaryColor="#FF5522"
              textColor="#FFFFFF"
              hoverColor="#FFFFFF"
              hoverTextColor="#FF5522"
              doSomething={handleSubmit}
              data-coming-btn
            />
          </form>
        </div>
        <div className={styles.imageContainer}>
          <img src={comingSoonImage} alt="Coming Soon" className={styles.comingSoonImage} />
        </div>
      </div>
    </section>
  );
};

export default WorkspaceSection;
