const getJwtToken = (): string | null => {
    // Retrieve token from cookies or sessionStorage
    const cookieValue = document.cookie
        .split('; ')
        .find(row => row.startsWith('auth_token='))
        ?.split('=')[1];
    if (cookieValue) {
        return cookieValue;
    }

    return sessionStorage.getItem('auth_token');
}

export default getJwtToken;