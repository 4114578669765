/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { logError } from "services/logService/errorLogger"
import { createSignature } from "utils/CreatePostSignatureUtil"
import { ApiMethod, APIOperations, MultipleRequestsResult, SignedApiResponse } from "./ApiTypes";
import { getDefaultHeaders, handleFetchResponse } from "./FetchUtils";
import { verifyGetRequest } from "./ApiHelper";
import RequestProps from "interface/RequestProps";
import storeJwtToken from "utils/StoreJwtToken";

const API_BASE_URL = process.env.REACT_APP_ENVIRONMENT === 'production' 
    ? process.env.REACT_APP_PROD_API_URL 
    : process.env.REACT_APP_DEV_API_URL

/**
 * Interface for API client properties.
 * 
 * @interface ApiClientProps
 * @property {string} TAG - Unique tag for identifying and logging API requests.
 * @property {string} url - The API endpoint URL.
 * @property {APIOperations} options - HTTP request options such as method, headers, and body.
 * @property {string | null} [signature] - Optional HMAC signature for request validation.
 */
interface ApiClientProps {
    TAG: string;
    url: string;
    options: APIOperations;
    signature?: string | null;
}

/**
 * Makes an API request using the provided parameters and returns the response.
 * 
 * @template T - The type of the response data.
 * @param {ApiClientProps} params - Parameters for the API client.
 * @returns {Promise<SignedApiResponse<T>>} - The signed API response as a promise.
 */
export async function apiClient<T>({ TAG, url, options, signature = null }: ApiClientProps): Promise<SignedApiResponse<T>> {
    try {
        const headers = await getDefaultHeaders(TAG, signature);
        const response = await fetch(`${API_BASE_URL}${url}`, { ...options, headers, credentials: 'include' });

        // Check if 'x-jwt-token' is present in the response headers
        const jwtToken = response.headers.get('x-jwt-token')
        if (jwtToken) {
            storeJwtToken(jwtToken, process.env.REACT_APP_NODE_ENV === 'production')
        }

        // Use handleFetchResponse to parse and validate the response
        const parsedResponse = await handleFetchResponse<T>(TAG, response);

        
        // Extract signature from headers
        const responseSignature = response.headers.get("X-Response-Signature") || null;

        return {
            response: parsedResponse,
            signature: responseSignature,
        };
    } catch (error) {
        logError("Error during API request", { error: error instanceof Error ? error.message : "Unknown error" }, TAG);

        return {
            response: {
                status: "error",
                message: "API request failed.",
                error: error instanceof Error ? error.message : "Unknown error",
                timestamp: new Date().toISOString(),
                data: null,
            },
            signature: null,
        };
    }
}

/**
 * Makes a GET request and verifies the response signature if applicable.
 * 
 * @template T - The type of the response data.
 * @param {RequestProps} params - The request properties.
 * @returns {Promise<ApiResponse<T>>} - The API response as a promise.
 * 
 * @example
 * const response = await get<{ userId: number }>({ tag: 'FetchUser', url: '/user/123' });
 */
/* eslint-disable @typescript-eslint/no-unsafe-call */
export async function get<T extends string | object | null>({ tag, url }: RequestProps): Promise<SignedApiResponse<T>> {
    const response = await apiClient<T>({
        TAG: tag,
        url,
        options: { method: 'GET' }
    })

    if (response.response.status === 'success') {
        const data = response.response.data ?? null
        //const signature = (response as any)?.headers?.get('X-Response-Signature') ?? null
        const signature = response.signature ?? ''

        const verificationResult = verifyGetRequest({
            tag,
            url,
            data,
            signature,
        })

        if (verificationResult.response.status !== 'success') {
            return verificationResult
        }
    }

    return response
}
/* eslint-enable @typescript-eslint/no-unsafe-call */

/**
 * Makes a POST request with the provided body and creates a signature for verification.
 * 
 * @template T - The type of the response data.
 * @param {RequestProps} params - The request properties including body.
 * @returns {Promise<ApiResponse<T>>} - The API response as a promise.
 * 
 * @example
 * const response = await post<{ success: boolean }>({
 *   tag: 'SignUp',
 *   url: '/user',
 *   body: { name: 'John Doe' }
 * });
 */
export async function post<T>({ tag, url, body }: RequestProps): Promise<SignedApiResponse<T>> {
    if (!body || typeof body !== 'object') {
        logError("Invalid body for POST request", { body, url }, tag);
        return {
            response: {
                status: 'error',
                message: 'Invalid request body.',
                error: "The request body must be a valid object.",
                timestamp: new Date().toISOString(),
            },
            signature: null,
        };
    }

    const signature = createSignature(body, tag);
    if (!signature) {
        logError('Signature creation failed', { body, url }, tag);
        return {
            response: {
                status: "error",
                message: "Signature creation failed.",
                error: "Unable to create a signature for the request.",
                timestamp: new Date().toISOString(),
            },
            signature: null,
        }
    }

    return apiClient<T>({
        TAG: tag,
        url,
        options: { method: 'POST', body: JSON.stringify(body) },
        signature
    });
}

/**
 * Handles multiple API requests concurrently and verifies GET responses if applicable.
 * 
 * @param {Record<string, { url: string; tag: string; method: ApiMethod; body?: any }>} requests - An object containing multiple request parameters.
 * @returns {Promise<MultipleRequestsResult>} - The results of all requests.
 * 
 * @example
 * const responses = await multipleRequests({
 *   request1: { url: '/user/123', tag: 'FetchUser', method: 'GET' },
 *   request2: { url: '/user', tag: 'CreateUser', method: 'POST', body: { name: 'John Doe' } }
 * });
 */
/* eslint-disable @typescript-eslint/no-unsafe-call */
export async function multipleRequests(
    requests: Record<string, { url: string; tag: string; method: ApiMethod; body?: any }>
): Promise<MultipleRequestsResult> {
    const results: MultipleRequestsResult = {};

    const promises = Object.entries(requests).map(async ([key, { url, tag, method, body }]) => {
        try {
            let options: APIOperations = { method };
            let signature: string | null = null;

            if ((method === 'POST' || method === 'PUT') && body && typeof body === 'object') {
                signature = createSignature(body, tag);
                if (!signature) {
                    throw new Error('Signature creation failed for POST/PUT request');
                }
                options = { ...options, body: JSON.stringify(body) };
            }

            const response = await apiClient({ TAG: tag, url, options, signature });

            if (method === 'GET' && response.response.status === 'success') {
                const data = response.response.data
                const responseSignature = (response as any)?.headers?.get('X-Response-Signature')

                if (data !== null && (typeof data === 'string' || typeof data === 'object')) {
                    const verificationResult = verifyGetRequest({
                        tag,
                        url,
                        data,
                        signature: responseSignature,
                    });

                    if (verificationResult.response.status !== 'success') {
                        results[key] = verificationResult;
                        return;
                    }
                } else {
                    logError('Unexpected data type for GET request signature verification', { data, url }, tag);
                    results[key] = {
                        response: {
                            status: "error",
                            message: "Invalid data type for signature verification.",
                            error: "Unable to verify the response.",
                            timestamp: new Date().toISOString(),
                        },
                        signature: null,
                    };
                    return;
                }
            }

            results[key] = response;
        } catch (error) {
            logError("Error in multipleRequests", { error: error instanceof Error ? error.message : "Unknown error", url, tag }, tag);
            results[key] = {
                response: {
                    status: "error",
                    message: "Request failed.",
                    error: error instanceof Error ? error.message : "Unexpected error",
                    timestamp: new Date().toISOString(),
                },
                signature: null,
            };
        }
    });

    await Promise.all(promises);
    return results;
}
/* eslint-enable @typescript-eslint/no-unsafe-call */
