import React from 'react'
import AnimatedBackgroundContainer from 'components/common/AnimatedBackgroundContainer'
import styles from 'styles/pages/SignUpPaymentPage.module.sass'
import SignUpPaymentForm from 'components/forms/SignUpPaymentForm';

/**
 * SignUpPaymentPage component for rendering the sign-up payment process.
 * It serves as the main entry point for users to enter their payment information
 * during the sign-up process.
 *
 * @component
 * 
 * @example
 * // Render the SignUpPaymentPage
 * <SignUpPaymentPage />
 * 
 * @see AnimatedBackgroundContainer - Component providing an animated background and optional footer.
 * @see SignUpPaymentForm - The form component for handling user payment input during sign-up.
 */
const SignUpPaymentPage: React.FC = () => {
    return (
        <div className={styles['signup-payment-page']}>
            <AnimatedBackgroundContainer showFooter={true}>
                <SignUpPaymentForm />
            </AnimatedBackgroundContainer>
        </div>
    )
};

export default SignUpPaymentPage;