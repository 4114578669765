import { PriceCardProps } from "interface/PriceCardProps";

/**
 * basicPricePlan object defines the properties and features of the basic subscription plan.
 * It includes pricing information, features, and a click handler for the subscription button.
 * 
 * @constant
 * 
 * @type {PriceCardProps}
 * 
 * @example
 * console.log(basicPricePlan.price);
 * // Output: "99.5"
 * 
 * @see PriceCardProps - Interface representing the structure of a price plan.
 */
export const basicPricePlan : PriceCardProps = {
    subscriptionType: 'basic',
    moreInfo: '1 User Seat',
    price: '99.5',
    nonAfricanPrice: '199',
    subscriptionTerm: 'per month',
    features: ['Access AI-Powered Workspace', '2 Workspace Projects per user per month', 'Access to Basic Reports', 'On-demand training.'],
    onButtonClick() {
        console.log('Basic plan clicked')
    },
}