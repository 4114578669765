import endpoints from "constants/api/Endpoints";
import { logError } from "services/logService/errorLogger";
import Account from "interface/Account";

const fileName = 'AccountApiService'

export const fetchProfile = async (level: 'summary' | 'profile'): Promise<Account | null> => {
    try {
        if (!level) {
            logError('Missing account retrieval level.', level, fileName)
            return null
        }

        const result = await endpoints.fetchAccountData({ tag: fileName, level })
        if (result.success && result.data) {
            return result.data
        }
        return null
    } catch (error) {
        logError(
            'Error retrieving account data',
            { error, level },
            fileName
        )
        return null
    }
}