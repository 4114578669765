import React, { useEffect, useState } from 'react'
import styles from 'styles/components/LandingHero.module.sass'
import systemMedia from 'assets/images/png/sytem-media-base.png'
import reportLineIcon from '@iconify/icons-mdi/report-line'
import arrowIcon from "@iconify/icons-ph/arrow-right"
import PrimaryButton from '../../common/PrimaryButton'
import SecondaryButton from '../../common/SecondaryButton'
import playIcon from '@iconify/icons-fluent/play-circle-16-regular'
import { Icon } from '@iconify/react'
import { motion } from 'framer-motion'
import { pulseAnimation } from 'assets/animations/PulseAnimation'
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'hooks/AuthContext'


interface LandingHeroProps {
    handleTogglePlayer: () => void;
}

/**
 * LandingHero component displays the main hero section of the landing page, introducing Zerone AnalytiQs and its features.
 * 
 * @component
 * 
 * @param {Object} props - The properties for configuring LandingHero.
 * @param {function} props.handleTogglePlayer - Function to toggle the media player when the play icon is clicked.
 * 
 * @example
 * // Renders the LandingHero component with a media player toggle function
 * <LandingHero handleTogglePlayer={() => console.log('Toggling player')} />
 */
const LandingHero:React.FC<LandingHeroProps> = ({ handleTogglePlayer }) => {
    const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 1024);
    const { user } = useAuth()

    const handleResize = () => {
        setIsMobile(window.innerWidth <= 1024);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    const navigate = useNavigate();

    const handleViewReports = () => {
        navigate('/report/categories');
        console.log('View Reports Clicked!')
    }

    const handleGoToWorkspace = () => {
        navigate('/workspace');
        console.log('Go to workspace clicked!')
    }
    const handleSignUpForFree = () => {
        navigate('/sign-up');
        console.log('Sign Up For Free clicked!')
    }


    return (
        <div className={styles['landing-hero-container']}>
            <div className={styles['left-wrapper']}>
                <p className={styles['short-intro']}>Discover consumer and market analytics with</p>
                <h3 className={styles['focus']}>Zerone AnalytiQs</h3>
                <p className={styles['short-desc']}>Make informed decisions about the African market today with our Data Visualization, Reports Generation & <br />
                    AI-Powered platform.</p>
                <div className={styles['control-buttons']}>
                    <PrimaryButton
                        text='View Reports'
                        primaryColor='#FF5522'
                        textColor='#FFFFFF'
                        hoverColor='#FFFFFF'
                        hoverTextColor='#FF5522'
                        icon={reportLineIcon}
                        doSomething={handleViewReports}
                        width={isMobile ? '100%' : 'auto'}
                    />
                    <SecondaryButton
                        text="Go to Workspace"
                        primaryColor="transparent"
                        textColor="#FFFFFF"
                        hoverColor="#ff5522"
                        hoverTextColor="#FFFFFF"
                        icon={arrowIcon}
                        doSomething={handleGoToWorkspace}
                        width={isMobile ? '100%' : 'auto'}
                    />
                    {!user && (             
                        <SecondaryButton
                            text='Sign Up For Free'
                            primaryColor='#FF5522'
                            textColor='#FFFFFF'
                            hoverColor='#FFFFFF'
                            hoverTextColor='#FF5522'
                            doSomething={handleSignUpForFree}
                            width={isMobile ? '100%' : 'auto'}
                        />
                    )}
                </div>
            </div>
            <div className={styles['right-wrapper']}>
                <div className='player-wrapper'>
                    <img src={systemMedia} />
                    <motion.div 
                        className={styles['play-icon']} 
                        animate={pulseAnimation} 
                        onClick={handleTogglePlayer}
                    >
                        <Icon icon={playIcon} className={styles['play-icon']}/>
                    </motion.div>
                </div>
            </div>
        </div>
    )
}

export default LandingHero;