import { ReportCategory } from "interface/ReportCategory";

/**
 * Test data array representing multiple report categories, each with metadata, description, and associated topics.
 *
 * @constant
 * @type {ReportCategory[]}
 * @default
 * 
 * @example
 * const zeroneReportCategories = [
 *   {
 *     id: "1aefd357-a6f2-4bf5-9910-e59be70bf99c",
 *     title: "Trending Topics",
 *     meta: {
 *       icon: "solar:graph-up-linear",
 *       primaryColor: "#C62E00",
 *       secondaryColor: "#FFEAE4"
 *     },
 *     description: "Brief overview of the category...",
 *     topics: [
 *       { id: "eadfa2f4-c477-42b9-bd85-6b2d2fd554bb", title: "The Power of AI", description: "This is a short description", dataset: "Dataset A", year: "2024" },
 *       { id: "05c1ba4d-552d-4dc1-bee0-eba6b68a7cc4", title: "TikTok Boom", description: "This is another short description", dataset: "Dataset B", year: "2024" }
 *     ]
 *   },
 *   // ...
 * ];
 */
export const zeroneReportCategories: ReportCategory[] = [
    {
        id: "1aefd357-a6f2-4bf5-9910-e59be70bf99c",
        title: "Trending Topics",
        meta: {
            icon: "solar:graph-up-linear",
            primaryColor: "#C62E00",
            secondaryColor: "#FFEAE4"
        },
        description: "Find reports on emerging topics, trending innovation and new technologies. Delve into well curated content on topical issues and technologies pertaining to the African continent. Check out reports and insights on topics such as artificial intelligence, agriculture, digitization, fintech, robotics and many more.",
        topics: [
            {
                id: "d6c92e98-25a8-491a-aeb3-4dd247bd18e3",
                title: "AFRICA'S DIGITIZATION FOOTPRINT",
                description: 'Dive into our "State of Data In Africa" report and discover how the global digital revolution is playing out in the African context. This report contains an analysis of Africa’s data landscape, including the rise in data centers, government spending on digitization and private sector initiatives, among others. It discusses data gaps in African and opportunities available in the market for investors, entrepreneurs and other players.',
                dataset: "Markets, Industries & Sectors",
                year: "2024",
                meta: {
                    cover: 'https://firebasestorage.googleapis.com/v0/b/zeroneanalytics-55fec.appspot.com/o/reports%2FAFRICAS%20DIGITIZATION%20FOOTPRINT%2Fafrica-digitization.jpg?alt=media&token=b8581cbd-e77f-42a3-8ed6-a75d622b4622'
                },
                category: ""
            }
        ]
    },
    {
        id: "e1a20e37-eefb-4d04-b921-f02f5524558a",
        title: "Markets, Industries & Sectors",
        meta: {
            icon: "cil:industry",
            primaryColor: "#009C4B",
            secondaryColor: "#D9FEEB"
        },
        description: "Under this category, we provide well researched and detailed reports into specific markets, industries and sectors in Africa. Find the latest on the aviation industry, banking sector, beverage market, mining, real estate, etc. Access insights and statistics on current state of markets/industries within Africa, and review forecasts and projections on potential future trends.",
        topics: [
            {
                id: "d6c92e98-25a8-491a-aeb3-4dd247bd18e3",
                title: "AFRICA'S DIGITIZATION FOOTPRINT",
                description: 'Dive into our "State of Data In Africa" report and discover how the global digital revolution is playing out in the African context. This report contains an analysis of Africa’s data landscape, including the rise in data centers, government spending on digitization and private sector initiatives, among others. It discusses data gaps in African and opportunities available in the market for investors, entrepreneurs and other players.',
                dataset: "Markets, Industries & Sectors",
                year: "2024",
                meta: {
                    cover: 'https://firebasestorage.googleapis.com/v0/b/zeroneanalytics-55fec.appspot.com/o/reports%2FAFRICAS%20DIGITIZATION%20FOOTPRINT%2Fafrica-digitization.jpg?alt=media&token=b8581cbd-e77f-42a3-8ed6-a75d622b4622'
                },
                category: ""
            },
            {
                id: "05c1ba4d-552d-4dc1-bee0-eba6b68a7cc4",
                title: "GHANA'S DOMESTIC AIRLINE MARKET",
                description: "In this report we present analysis, findings and insights on the domestic commercial airline market in Ghana. It contains a detailed review of the competitive landscape including who the major players are, market shares and competition strategies. We project key metrics such as revenue passenger miles (RPM), Available Seat Miles (ASM), Passenger load factor, etc. The report also contains customer preference, insights and sentiments on specific airlines and the industry as a whole.",
                dataset: "Markets, Industries & Sectors",
                year: "2024",
                meta: {
                    cover: 'https://firebasestorage.googleapis.com/v0/b/zeroneanalytics-55fec.appspot.com/o/reports%2FGHANAS%20DOMESTIC%20AIRLINE%20MARKET%2Fdomestic-airline.jpg?alt=media&token=ca757880-9663-4801-b40a-100852be2120'
                },
                category: ""
            }
        ]
    },
    {
        id: "5958feca-b61b-4b51-8406-67485d2e2ace",
        title: "Products & Consumer Insights",
        meta: {
            icon: "solar:cart-broken",
            primaryColor: "#13757B",
            secondaryColor: "#D7FDFF"
        },
        description: "Find out about the preferences of African consumers and discover what products, services and brands match up in taste, quality and other characteristics. With access to several thousands of consumers on our ZeroneData app, our products and consumer reports provide relevant and data-driven insights into consumer behaviors, sentiments, trends and preferences.",
        topics: [
            {
                id: "07de320e-90a4-45c0-9022-ad65a28d1e2b",
                title: "CHOOSING A TOOTHPASTE: CLOSE-UP",
                description: 'This report provides some of the most interesting facts and figures relating to consumer preferences when it comes to choosing a toothpaste brand in Ghana. After analyzing what features in a toothpaste attracts customers the most, it dives into attractiveness of the Close-Up brand to consumers. The report shows that while more men use Close-Up compared to women, both men and women look to their toothpaste to do similar things for their oral hygiene.',
                dataset: "Products & Consumer Insights",
                year: "2024",
                meta: {
                    cover: 'https://firebasestorage.googleapis.com/v0/b/zeroneanalytics-55fec.appspot.com/o/reports%2FAFRICAS%20DIGITIZATION%20FOOTPRINT%2Fafrica-digitization.jpg?alt=media&token=b8581cbd-e77f-42a3-8ed6-a75d622b4622'
                },
                category: ""
            }
        ]
    },
    {
        id: "2f361e26-2359-429f-bd9b-3a96f6897906",
        title: "Countries & Economies",
        meta: {
            icon: "streamline:politics-vote-2",
            primaryColor: "#9747FF",
            secondaryColor: "#F0E4FF"
        },
        description: "Discover comprehensive reports on many economies and countries in Africa. Find out more about key economic and development indicators such as population, GDP, growth rates, demographics and more. Get access to nuanced statistics such as patent and copyright registrations per country, cellphone usage penetration and the interaction between women, the law and easy of business involvement.",
        topics: [    
            {
                id: "5740dba9-85e5-4286-9f00-0307ed841b51",
                title: "SHORT-STAY RENTAL IN KENYA",
                description: "Discover how players in Kenya’s short-term hospitability sector are pricing their units and what features have the biggest sway on guests. This report contains analysis of several Airbnb listings in Kenya in 2024. It assesses which neighborhoods command premium pricing, the effects of guest ratings/reviews on success and analyzes the impact of hosts gender, etc.",
                dataset: "Countries & Economies",
                year: "2024",
                meta: {
                    cover: 'https://firebasestorage.googleapis.com/v0/b/zeroneanalytics-55fec.appspot.com/o/reports%2FSHORT-STAY%20RENTAL%20IN%20KENYA%2Fshort-stay-rental-kenya.jpg?alt=media&token=13260ce0-a94c-45df-a995-60e27d78eb99'
                },
                category: ""
            }
        ]
    }
]