import ChangePlan from "interface/ChangePlan";
import { SignUpResponse } from "../models/SignUpResponse";
import { fetchApiData } from "../ApiHelper";
import EndpointProps from "interface/EndpointProps";
import { get, post } from "../ApiClient";
import UpdatePayment from "interface/UpdatePayment";
import { DefaultApiResponse } from "../models/response/DefaultApiResponse";
import UserSubscription from "interface/UserSubscription";

/* eslint-disable @typescript-eslint/no-redundant-type-constituents */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
export async function updateSubscriptionPlan({
    tag,
    data,
}: EndpointProps & { data: ChangePlan }): Promise<{ data: SignUpResponse | null; error: string | null }> {
    if (!data) {
        return {
            data: null,
            error: "Invalid change plan request: Missing data payload.",
        };
    }

    const result = await fetchApiData<SignUpResponse>(
        tag,
        () =>
            post<SignUpResponse>({
                tag,
                url: "/subscription/change/plan",
                body: data,
            }),
        (responseData) => responseData
    );

    if (result.response.status === "error") {
        return {
            data: null,
            error: result.response.message || "An error occurred while updating plan.",
        };
    }

    return {
        ...result.response.data || null,
        error: null,
    };
}

export async function updateSubscriptionPayment({ tag, data }: EndpointProps & { data: UpdatePayment }): Promise<boolean> {
    if (!data) {
        throw new Error ('Invalid payment update request: Missing data payload.')
    }

    const result = await fetchApiData<DefaultApiResponse>(tag,
        () => post<DefaultApiResponse>({ tag, url: '/subscription/payment', body: data }),
        (responseData) => responseData
    )

    return result.response.status === 'success'
}

export async function fetchSubscriptionData({ tag }: EndpointProps): Promise<{ success: boolean; data?: UserSubscription }> {
    const result = await fetchApiData<DefaultApiResponse<UserSubscription>>(tag,
        () => get<DefaultApiResponse<UserSubscription>>({ tag, url: `/subscription`}),
        (responseData) => responseData.data
    )

    return {
        success: result.response.status === 'success',
        data: result.response.data
    }
}

/* eslint-enable @typescript-eslint/no-redundant-type-constituents */
/* eslint-enable @typescript-eslint/no-unsafe-return */
/* eslint-enable @typescript-eslint/no-unsafe-argument */