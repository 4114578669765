import BlogPostParagraph from 'components/common/BlogPostParagraph';
import { BlogContent } from 'interface/BlogContent'
import React from 'react'
import styles from 'styles/layout/BlogPostContentSection.module.sass'
import { v4 as uuid } from 'uuid'

/**
 * BlogPostContentProps interface defines the props for the BlogPostContentSection component.
 * 
 * @interface BlogPostContentProps
 * @property {BlogContent | null} blog - The blog content to be displayed. If null, the content section is empty.
 */
interface BlogPostContentProps {
    blog: BlogContent | null;
}

/**
 * BlogPostContentSection component - Renders the content of a blog post, including paragraphs, references, and footer.
 * 
 * @component
 * @param {BlogPostContentProps} props - The props for the component.
 * @returns {JSX.Element} The rendered BlogPostContentSection component.
 *
 * @example
 * // Example usage:
 * <BlogPostContentSection blog={blogData} />
 */
const BlogPostContentSection: React.FC<BlogPostContentProps> = ({ blog }) => {
    if (!blog) return <section className={styles['blog-post-content']}></section>
    return (
        <section className={styles['blog-post-content']}>
            {blog.paragraphs?.map((paragraph) => (
                <BlogPostParagraph key={uuid()} paragraph = {paragraph} />
            ))}
            {blog.references && (
                <div className={styles['reference-wrapper']}>
                    <h3>Reference</h3>
                    {blog.references?.map((reference) => (
                        <a 
                            href={reference.href} 
                            key={reference.text}
                            target='_blank'
                            rel="noopener noreferrer" 
                            className={styles['reference']}
                        >
                            {reference.text}
                        </a>
                    ))}
                </div>
            )}
            {blog.attributions && (
                <div className={styles['reference-wrapper']}>
                    <h3>Attributions</h3>
                    {blog.attributions?.map((attribution) => (
                        <a
                            href={attribution.href}
                            key={attribution.text}
                            target='_blank'
                            rel='noopener noreferrer'
                            className={`${styles['reference']} ${styles['attribution-link']}`}
                        >
                            {attribution.text}
                        </a>
                    ))}
                </div>
            )}
            <div className={styles['footer']}>
                <p>Copyright © {new Date().getFullYear()} Zerone Analytiqs. All rights reserved.</p>
            </div>
        </section>
    )
}

export default BlogPostContentSection;