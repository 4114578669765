import CountryValue from 'interface/CountryValue';
import countryData from './countries.json';

export const countryDetails: Record<string, CountryValue> = {};

countryData.forEach((country) => {
    countryDetails[country.code] = {
        name: country.name,
        code: country.dial_code,
        iso: country.code,
    };
});

export default countryDetails;