import styles from 'styles/components/Toast.module.sass'
import ReusableIcon from "./ReusableIcon";
import { format } from "date-fns/format";
import ToastData from "interface/ToastData";

/**
 * Toast Component
 *
 * Renders a single toast notification with customizable message, type, and actions.
 * Supports a close button, optional link, and type-specific styles and icons.
 *
 * @component
 * @example
 * ```tsx
 * import Toast from './Toast';
 * import ReusableIcon from './ReusableIcon';
 *
 * const Example = () => (
 *   <Toast
 *     id="53fd174c-122c-476d-8062-bc8258babed2"
 *     message="This is a success message."
 *     type="success"
 *     time={new Date()}
 *     onClose={() => console.log('Toast closed')}
 *     link={{ url: 'https://zeroneanalytiqs.com', text: 'View More' }}
 *   />
 * );
 * ```
 *
 * @param {ToastData} props - The properties for the Toast component.
 * @returns {JSX.Element} - The JSX element for a toast notification.
 */
const Toast: React.FC<ToastData> = ({ id, message, type = 'info', time = new Date(), onClose = () => {}, link }) => {
    const COLORS: Record<ToastData['type'], string> = {
        success: '#4CAF50',
        warning: '#FFC148',
        error: '#dc3545',
        pending: '#005581',
        info: '#707070',
    };

    const ICONS: Record<ToastData['type'], string> = {
        success: 'qlementine-icons:success-16',
        warning: 'qlementine-icons:warning-16',
        error: 'qlementine-icons:spam-16',
        pending: 'qlementine-icons:clock-16',
        info: 'qlementine-icons:info-16',
    };

    const color = COLORS[type]
    const icon = ICONS[type]
    const formattedTime = format(time, 'h:mm a')

    return (
        <div key={id} className={styles['toast']} style={{ borderLeft: `4px solid ${color}` }}>
            <ReusableIcon 
                icon={icon} 
                className={styles['icon'] || 'icon'} 
                color={color} 
            />
            <div className={styles['content']}>
                <h4 className={styles['title']} style={{color: `${type !== 'info'? color: '#005581'}`}}>
                    {type.charAt(0).toUpperCase() + type.slice(1)}
                </h4>
                <p className={styles['message']}>{message}</p>
                <div className={styles['footer']}>
                    <span className={styles['time']}>{formattedTime}</span>
                    {link && (
                        <a 
                            href={link.url} 
                            target="_blank" 
                            rel="noopener noreferrer"
                            className={styles['link']}
                        >{link.text}</a>
                    )}
                </div>
            </div>
            <button className={styles['close-button']} onClick={onClose}>
                <ReusableIcon 
                    icon={"qlementine-icons:close-16"} 
                    className={styles['close-icon'] || 'close-icon'}
                    color={"#707070"}
                />
            </button>
        </div>
    )
}

export default Toast;