import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import styles from 'styles/components/HeaderNavigation.module.sass'
import zeroneLogo from 'assets/images/svg/logo-light.svg'
import zeroneLogoDark from 'assets/images/svg/logo-dark.svg'
import menuIcon from 'assets/images/svg/menu-burger.svg'
import menuIconDark from 'assets/images/svg/menu-burger-dark.svg'
import closeIcon from '@iconify/icons-mdi/close'
import { Icon } from '@iconify/react'
import PrimaryButton from 'components/common/PrimaryButton'
import SecondaryButton from 'components/common/SecondaryButton'
import { useAuth } from 'hooks/AuthContext'
import ReusableIcon from 'components/common/ReusableIcon'

interface HeaderNavigationProps {
    theme?: string;
}

/**
 * HeaderNavigation component provides a responsive navigation bar with links and action buttons for desktop and mobile views.
 * 
 * @component
 * 
 * @param {Object} props - Props for the HeaderNavigation component.
 * @param {string} [props.theme='light'] - Optional theme parameter to switch between light and dark logos and styles.
 * 
 * @example
 * // Renders the HeaderNavigation component with light theme
 * <HeaderNavigation theme="light" />
 * 
 * @description
 * The desktop header displays navigation links and action buttons side-by-side, while the mobile header features a toggleable menu.
 * Links include options such as "Pricing & Solutions" and "Blog," while action buttons include "Contact us" and "Sign Up."
 * Clicking the menu button on mobile expands the navigation options and actions, and the toggle effect changes the button icon.
 */
const HeaderNavigation: React.FC<HeaderNavigationProps> = ({ theme = 'light' }) => {
    const [menuOpen, setMenuOpen] = useState(false)
    const [ accountTypeIcon, setAccountTypeIcon ] = useState<string | null>(null)
    const navigate = useNavigate()
    const toggleMenu = () => setMenuOpen(!menuOpen)
    const { user, userSubscription, logout } = useAuth()

    useEffect(() => {
        switch (userSubscription?.name) {
            case ('basic'):
                setAccountTypeIcon('material-symbols-light:workspace-premium-outline')
                break;
            case ('sapphire'):
                setAccountTypeIcon('hugeicons:crown')
                break;
            case ('gold'):
                setAccountTypeIcon('hugeicons:gold')
                break;
            case ('premium'):
                setAccountTypeIcon('fluent:premium-person-16-filled')
                break;
            default:
                setAccountTypeIcon('fluent:clock-32-light')
        }
    }, [user, userSubscription])

    const handleLogIn = () => {
        navigate('/login')
    }

    const handleSignUp = () => {
        navigate('/sign-up')
    }

    console.log(userSubscription)

    return (
        <div className={styles['header-navigation-container']}>
            <div className={styles['desktop-header']}>
                <div className={styles['logo-wrapper']}>
                    <Link to={"/"}>
                        <img src={theme === 'light' ? zeroneLogo : zeroneLogoDark} alt='Zerone AnalytiQs Logo' />
                    </Link>
                </div>

                <div className={styles['nav-and-actions']}>
                    <nav className={`${styles['nav-links']}`} style={{ color: theme === 'light' ? '#fff' : '#005581' }}>
                        <Link to="/pricing" className={styles['nav-link']}>Pricing & Solutions</Link>
                        <Link to="/workspace" className={styles['nav-link']}>Workspace <span className={styles['coming-soon']}>Coming Soon</span></Link>
                        <Link to="/report/categories" className={styles['nav-link']}>Reports</Link>
                        {/* <Link to="/datasets" className={styles['nav-link']}>Datasets</Link> */}
                        <Link to="/about-us" className={styles['nav-link']}>About Us</Link>
                        <Link to="/blogs" className={styles['nav-link']}>Blog</Link>
                        <Link to="/contact-us" className={styles['nav-link']}>Contact Us</Link>
                    </nav>

                    { !user ? (
                        <div className={styles['action-buttons']}>
                            <SecondaryButton
                                text="Log in"
                                primaryColor= "transparent"
                                textColor={theme === 'light' ? "#FFFFFF" : '#ff5522'}
                                hoverColor="#ff5522"
                                hoverTextColor="#FFFFFF"
                                doSomething={handleLogIn}
                            />                
                            <PrimaryButton
                                    text='Sign Up'
                                    primaryColor='#FF5522'
                                    textColor='#FFFFFF'
                                    hoverColor='#FFFFFF'
                                    hoverTextColor='#FF5522'
                                    doSomething={handleSignUp}
                            />
                        </div>
                    ) : (
                        <div className={styles['user-action-menu']}>
                            <div className={styles['avatar']} style={{ borderColor: theme === 'light' ? '#fff' : '#005581' }}>
                                <ReusableIcon 
                                    icon={'mynaui:user-circle-solid'} 
                                    className={styles['avatar-icon'] || 'avatar-icon'} 
                                    color={theme === 'light' ? '#fff' : '#005581'} 
                                />
                            </div>
                            <div className={styles['quick-actions-wrapper']}>
                                <div className={styles['quick-action-item']}>     
                                    <div className={styles['icon-info']}>
                                        {accountTypeIcon && (
                                            <ReusableIcon 
                                                icon={accountTypeIcon} 
                                                className={styles['action-icon'] || 'action-icon'} 
                                                color={theme === 'light' ? '#707070' : '#005581'} 
                                            />
                                        )}
                                        <span>{user.name}</span>
                                    </div>
                                </div>
                                <div className={styles['quick-action-item']}>
                                    <div className={styles['icon-info']}>
                                        <ReusableIcon 
                                            icon={'mdi:account-balance-wallet-outline'} 
                                            className={styles['action-icon'] || 'action-icon'} 
                                            color={theme === 'light' ? '#707070' : '#005581'} />
                                            <span>{user.credits}</span> {user.credits === 1 ? 'Report Credit' : 'Credits'}
                                    </div>
                                </div>
                                                
                                <SecondaryButton
                                        text='Logout'
                                        width='100%'
                                        primaryColor='#FFFFFF'
                                        textColor='#707070'
                                        hoverColor='#FFFFFF'
                                        hoverTextColor='#FF5522'
                                        doSomething={logout}
                                />
                            </div>
                        </div>
                    )}
                    
                </div>
            </div>
            <div className={styles['mobile-header']}>
                <div 
                    className={styles['top-wrapper']}
                    style={{  
                        borderImage: menuOpen ? 'linear-gradient(90deg, rgba(92, 150, 181, 0.1) 0%, #6FA8C5 50%, rgba(92, 150, 181, 0.1) 100%) 1' : 'none'
                    }}
                >
                    <Link to={"/"}>
                        <img src={theme === 'light' ? zeroneLogo : zeroneLogoDark} alt='Zerone AnalytiQs Logo' />
                    </Link>

                    <button onClick={toggleMenu} className={styles['menu-toggle']}>
                        {menuOpen ? (
                            <Icon icon={closeIcon} style={{ color: theme === 'light' ? '#fff' : '#005581' }} />
                        ) : (
                            <img src={theme === 'light' ? menuIcon : menuIconDark} />
                        )}
                        
                    </button>
                </div>

                {menuOpen && (
                    <div className={styles['bottom-wrapper']}>
                        <nav className={`${styles['mobile-nav-links']}`} style={{ color: theme === 'light' ? '#fff' : '#005581' }}>
                            <Link to="/pricing" className={styles['mobile-link']} aria-label='Pricing Page'>Pricing & Solutions</Link>
                            <Link to="/workspace" className={styles['mobile-link']} aria-label='Workspace PAge'>Workspace  <span className={styles['coming-soon']}>Coming Soon</span></Link>
                            <Link to="/report/categories" className={styles['mobile-link']} aria-label='Reports Page'>Reports</Link>
                            {/* <Link to="/datasets" className={styles['mobile-link']}>Datasets</Link> */}
                            <Link to="/about-us" className={styles['mobile-link']} aria-label='About Us Page'>About Us</Link>
                            <Link to="/blogs" className={styles['mobile-link']} aria-label='Blog Page'>Blog</Link>
                            <Link to="/contact-us" className={styles['nav-link']}>Contact Us</Link>
                        </nav>

                        <div className={styles['mobile-action-buttons']}>
                            <SecondaryButton
                                text="Log in"
                                primaryColor="transparent"
                                textColor={theme === 'light' ? "#FFFFFF" : '#ff5522'}
                                hoverColor="#ff5522"
                                hoverTextColor="#FFFFFF"
                                doSomething={handleLogIn}
                            />                
                            <PrimaryButton
                                    text='Sign Up'
                                    primaryColor='#FF5522'
                                    textColor='#FFFFFF'
                                    hoverColor='#FFFFFF'
                                    hoverTextColor='#FF5522'
                                    doSomething={handleSignUp}
                            />
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default HeaderNavigation