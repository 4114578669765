import { BlogContent } from "interface/BlogContent";
import { fetchApiData } from "../ApiHelper";
import { get } from "../ApiClient";
import EndpointProps from "interface/EndpointProps";
import FetchDataByIDProps from "interface/FetchDataByIDProps";

/**
 * Fetches a list of blog content from the server.
 * 
 * This function performs an API call to retrieve blog content using the provided tag and
 * processes the API response using the `fetchApiData` utility.
 * 
 * @async
 * @function getBlogs
 * @param {EndpointProps} params - The request parameters containing a unique tag for identifying the request.
 * @returns {Promise<{ data: BlogContent[]; error: string | null }>} - A promise that resolves to an object containing the array of `BlogContent` and any error message.
 * 
 * @example
 * async function loadBlogs() {
 *   const result = await getBlogs({ tag: 'BlogFetch' });
 *   if (result.error) {
 *     console.error('Error fetching blogs:', result.error);
 *   } else {
 *     console.log('Fetched blogs:', result.data);
 *   }
 * }
 */
export async function getBlogs({ tag }: EndpointProps): Promise<{ data: BlogContent[]; error: string | null }> {
    const result = await fetchApiData<{ blogs: BlogContent[] }>(
        tag,
        () => get<{ blogs: BlogContent[] }>({ tag, url: '/blogs' }),
        (data) => data.blogs || []
    );

    // Transform the SignedApiResponse into the expected format
    return {
        data: result.response.status === "success" ? result.response.data : [],
        error: result.response.status === "error" ? result.response.error ?? null : null,
    };
}

/**
 * Fetches the hot topic blog content from the server.
 * 
 * This function performs an API call to retrieve the hot topic blog post
 * and processes the response using the `fetchApiData` utility.
 * 
 * @async
 * @function getHotTopic
 * @param {EndpointProps} params - The request parameters containing a unique tag for identifying the file making the request.
 * @returns {Promise<{ data: BlogContent | null; error: string | null }>} - A promise that resolves to an object containing the `BlogContent` or null if not found, and any error message.
 * 
 * @example
 * async function loadHotTopic() {
 *   const result = await getHotTopic({ tag: 'HotTopicFetch' });
 *   if (result.error) {
 *     console.error('Error fetching hot topic:', result.error);
 *   } else {
 *     console.log('Fetched hot topic:', result.data);
 *   }
 * }
 */
export async function getHotTopic({ tag }: EndpointProps): Promise<{ data: BlogContent | null; error: string | null }> {
    const result = await fetchApiData<{ blog: BlogContent }>(
        tag,
        () => get<{ blog: BlogContent }>({ tag, url: '/hot-trend' }),
        (data) => data.blog || null
    );

    return {
        data: result.response.status === "success" ? result.response.data : null,
        error: result.response.status === "error" ? result.response.error ?? null : null,
    };
}

/**
 * Fetches a specific blog post from the server using the provided ID.
 * 
 * This function performs an API call to retrieve a blog post based on the given parameters
 * and processes the API response using the `fetchApiData` utility.
 * 
 * @async
 * @function getBlogPost
 * @param {FetchBlogPostProps} params - The request parameters containing the tag and unique blog post ID.
 * @returns {Promise<{ data: BlogContent | null; error: string | null }>} - A promise that resolves to an object containing the `BlogContent` or null if not found, and any error message.
 * 
 * @example
 * async function loadBlogPost() {
 *   const result = await getBlogPost({ tag: 'BlogPostFetch', id: '123' });
 *   if (result.error) {
 *     console.error('Error fetching blog post:', result.error);
 *   } else {
 *     console.log('Fetched blog post:', result.data);
 *   }
 * }
 */
export async function getBlogPost({ tag, id }: FetchDataByIDProps): Promise<{ data: BlogContent | null; error: string | null }> {
    const result = await fetchApiData<{ blog: BlogContent }>(
        tag,
        () => get<{ blog: BlogContent }>({ tag, url: `/blogs/${id}` }),
        (data) => data.blog || null
    );

    return {
        data: result.response.status === "success" ? result.response.data : null,
        error: result.response.status === "error" ? result.response.error ?? null : null,
    };
}
