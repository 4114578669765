import AccountType from "interface/AccountType";

/**
 * List of available account types for the application.
 * 
 * Each account type includes the name, description, and associated color for visual representation.
 * This data is used in account selection forms and plan displays throughout the application.
 * 
 * @constant {AccountType[]} accountTypes
 * 
 * @property {string} name - The unique name of the account type (e.g., "free", "basic").
 * @property {string} description - A detailed explanation of the features and benefits associated with the account type.
 * @property {string} color - A hex code representing the visual color associated with the account type.
 * 
 * @example
 * // Accessing account type details
 * console.log(accountTypes.find(account => account.name === 'gold'));
 * 
 * @see AccountType - The interface defining the structure of each account type.
 */
const accountTypes: AccountType[] = [
    {
        name: "free",
        description: "This gives you access to blogs and free trending reports. You can use this account to get a sneak peek into our offerings until you’re ready",
        color: "#000000"
    },
    {
        name: "basic",
        description: "Zerone Basic offers individuals and sole proprietors full access to an AI-powered workspace with 2 monthly projects and comprehensive reports. It includes on-demand training to boost productivity and supports data-driven decision-making. Annual prepayment comes with a 10% discount.",
        color: "#216F97"
    },
    {
        name: "sapphire",
        description: "Zerone Sapphire caters to small teams and businesses, providing full access to an AI-powered workspace for up to 5 users. It allows 3 projects per user monthly, offers 3 premium report credits, and includes an annual custom dataset. On-demand training is available to maximize AI-driven insights.",
        color: "#003FD1"
    },
    {
        name: "gold",
        description: "Zerone Gold is designed for businesses and corporations, offering full access to an AI-powered workspace for up to 10 users. It includes 7 projects per user monthly and 7 premium or customized reports. The plan features quarterly custom datasets and semi-annual training to enhance analytics capabilities and support enterprise decision-making.",
        color: "#E88B00"
    },
    {
        name: "platinum",
        description: "Zerone Platinum serves large-scale users and custom projects with unlimited seats and projects. It provides full access to all report types, monthly custom data collection, analysis, and reporting. The plan includes regular training to keep teams ahead and offers a 10% discount for annual prepayment.",
        color: "#BCBCBC"
    }
]

export default accountTypes;