import { fetchApiData } from "../ApiHelper"
import { get } from "../ApiClient"
import { DefaultApiResponse } from "../models/response/DefaultApiResponse"
import EndpointProps from "interface/EndpointProps"
import Account from "interface/Account"

export async function fetchAccountData(
    { tag, level }: EndpointProps & { level: 'summary' | 'profile' }
): Promise<{ success: boolean; data?: Account }> {
    if (!level) {
        throw new Error ('Invalid account level data request.')
    }

    const result = await fetchApiData<DefaultApiResponse<Account>>(tag,
        () => get<DefaultApiResponse<Account>>({ tag, url: `/account/${level}`}),
        (responseData) => responseData.data
    )

    return {
        success: result.response.status === 'success',
        data: result.response.data
    }
}