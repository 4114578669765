import { ReportContent } from "interface/ReportContent";
import { DebbieOppongAsumang } from "../author/DebbieOppongAsumang";

/**
 * `AfricasDigitizationFootPrint` is a constant representing the report titled "The State of Data in Africa."
 * 
 * @constant {ReportContent}
 * 
 * @property {string} id - The unique identifier for the report.
 * @property {string} title - The title of the report.
 * @property {string} description - A summary of the report's content.
 * @property {string} year - The year of publication.
 * @property {string} dataset - The dataset category of the report.
 * @property {Object} meta - Metadata related to the report.
 * @property {string} meta.cover - The URL to the cover image of the report.
 * @property {Author} author - The author of the report.
 * @property {Array<Object>} links - An array of links to access the report.
 * @property {string} links[].type - The type of file (e.g., PDF).
 * @property {string} links[].version - The version of the report.
 * @property {string} links[].link - The URL to download the report.
 * 
 * @example
 * import { AfricasDigitizationFootPrint } from "path/to/reports";
 * 
 * console.log(AfricasDigitizationFootPrint.title); // Outputs: "Africa's Digitization Footprint"
 * console.log(AfricasDigitizationFootPrint.links[0].link); // Outputs the URL to the PDF file.
 */
export const AfricasDigitizationFootPrint: ReportContent = {
    id: "d6c92e98-25a8-491a-aeb3-4dd247bd18e3",
    title: "AFRICA'S DIGITIZATION FOOTPRINT",
    description: 'Dive into our "State of Data In Africa" report and discover how the global digital revolution is playing out in the African context. This report contains an analysis of Africa’s data landscape, including the rise in data centers, government spending on digitization and private sector initiatives, among others. It discusses data gaps in African and opportunities available in the market for investors, entrepreneurs and other players.',
    year: "2024",
    dataset: "Digital Technology",
    meta: {
        cover: 'https://firebasestorage.googleapis.com/v0/b/zeroneanalytics-55fec.appspot.com/o/reports%2FAFRICAS%20DIGITIZATION%20FOOTPRINT%2Fafrica-digitization.jpg?alt=media&token=b8581cbd-e77f-42a3-8ed6-a75d622b4622'
    },
    author: DebbieOppongAsumang,
    links: [
        {
            type: "PDF",
            version: "1.0",
            link: "https://firebasestorage.googleapis.com/v0/b/zeroneanalytics-55fec.appspot.com/o/reports%2Fstate_of_data%20in_africa.pdf?alt=media&token=c4a1606a-ab7e-4d4e-a575-a4f793325bb4"
        }
    ],
    tableOfContents: [],
    category: ""
}
