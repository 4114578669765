import { PriceCardProps } from "interface/PriceCardProps";

/**
 * saphirePricePlan object defines the properties and features of the sapphire subscription plan.
 * It includes pricing details, a selection of features, and a click handler for the subscription button.
 * 
 * @constant
 * 
 * @type {PriceCardProps}
 * 
 * @example
 * console.log(saphirePricePlan.moreInfo);
 * // Output: "5 User  Seats"
 * 
 * @see PriceCardProps - Interface representing the structure of a price plan.
 */
export const saphirePricePlan : PriceCardProps = {
    subscriptionType: 'saphire',
    moreInfo: '5 User Seats',
    price: '199.5',
    nonAfricanPrice: '399',
    subscriptionTerm: 'per month',
    features: ['Access AI-Powered Workspace', '3 Workspace Projects per user per month', 'Access to Intermediate (Basic + some premium) Reports', 'On-demand training.'],
    onButtonClick() {
        console.log('Saphire plan clicked')
    },
}