import InputField from 'components/common/InputField';
import React, { useEffect, useState } from 'react';
import styles from 'styles/components/SignUpForm.module.sass';
import { Link, useNavigate } from 'react-router-dom';
import SecondaryButton from 'components/common/SecondaryButton';
import PrimaryButton from 'components/common/PrimaryButton';
import CountryPicker from 'components/common/CountryPicker';
import CountryValue from 'interface/CountryValue';
import { validateAndSanitizeCreateAccount } from 'utils/ValidateCreateAccount';
import getDefaultCountryFromLocale from 'utils/DefaultCountryFromLocale';
import countryDetails from 'constants/data/global/CountryData';
import { useToast } from 'provider/ToastProvider';

/**
 * SignUpForm component renders a user registration form with fields for personal details,
 * and action buttons for signing up or logging in.
 *
 * @component
 * @example
 * Example usage:
 * 
 * ```tsx
 * import SignUpForm from 'components/SignUpForm';
 * 
 * const App: React.FC = () => (
 *   <div>
 *     <SignUpForm />
 *   </div>
 * );
 *
 * export default App;
 * ```
 *
 * @returns {JSX.Element} The JSX element displaying the sign-up form.
 */
const SignUpForm: React.FC = () => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    const { addToast } = useToast()
    const [validationErrors, setValidationErrors] = useState<string[]>([])
    const [firstName, setFirstName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [gender, setGender] = useState<string>('');
    const [country, setCountry] = useState<string>('');
    const [phone, setPhone] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const [countryCode, setCountryCode] = useState<string>('')

    const navigate = useNavigate();

    useEffect(() => {
        const defaultCountryISO = getDefaultCountryFromLocale()
        const defaultCountry = countryDetails[defaultCountryISO]

        if (defaultCountry) {
            setCountry(defaultCountry.name || '')
            setCountryCode(defaultCountry.iso || '')
            setPhone(defaultCountry.code || '')
        }
    }, [])

    function handleLoginClicked() {
        navigate('/login')
    }

    function handleNextClicked(event: React.FormEvent) {
        event.preventDefault()
        setValidationErrors([])

        const {
            isValid,
            sanitizedFirstName,
            sanitizedLastName,
            sanitizedEmail,
            sanitizedGender,
            sanitizedCountry,
            sanitizedCountryCode,
            sanitizedPhone,
            sanitizedPassword,
            sanitizedConfirmPassword,
            errors
        } = validateAndSanitizeCreateAccount({
            firstName,
            lastName,
            email,
            gender,
            country,
            countryCode,
            phone,
            password,
            confirmPassword
        })

        if (isValid) {
            navigate('/account-type', {
                state: {
                    firstName: sanitizedFirstName,
                    lastName: sanitizedLastName,
                    email: sanitizedEmail,
                    gender: sanitizedGender,
                    country: sanitizedCountry,
                    countryCode: sanitizedCountryCode,
                    phone: sanitizedPhone,
                    password: sanitizedPassword,
                    confirmPassword: sanitizedConfirmPassword
                }
            });
        } else {
            setValidationErrors(errors)

            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            addToast({
                message: validationErrors[0] || 'Invalid input',
                type: 'error',
            })
        }
    }

    const handleCountryChange = (selectedCountry: CountryValue) => {
        requestAnimationFrame(() => {
            setCountryCode(selectedCountry.iso || '')
            setCountry(selectedCountry.name || '')
            setPhone(selectedCountry.code || '')
        });
    };

    return (
        <form className={styles['sign-up-form']}>
            <h2 className={styles['form-title']}>Create an Account</h2>
            <div className={styles['all-input']}>

            <div className={styles['input-row']}>
                <InputField 
                    label={'First Name'} 
                    type={'text'} 
                    value={firstName} 
                    onChange={setFirstName} 
                    required={true} 
                />
                <InputField 
                    label={'Last Name'} 
                    type={'text'} 
                    value={lastName} 
                    onChange={setLastName} 
                    required={true} 
                />
            </div>

            <div className={styles['input-row']}>
                <InputField 
                    label={'Email'} 
                    type={'email'} 
                    value={email} 
                    onChange={setEmail} 
                    required={true} 
                />
            </div>

            <div className={styles['input-container']}>
                <label className={styles['label']}>
                    Gender<span className={styles['required']}>*</span>
                </label>
                <div className={styles['radio-group']}>
                    <label className={styles['radio-label']}>
                        <input 
                            type="radio" 
                            value="male" 
                            checked={gender === 'male'} 
                            onChange={(e) => setGender(e.target.value)} 
                            className={styles['radio-input']}
                        />
                        <span className={styles['radio-text']}>Male</span>
                    </label>
                    <label className={styles['radio-label']}>
                        <input 
                            type="radio" 
                            value="female" 
                            checked={gender === 'female'} 
                            onChange={(e) => setGender(e.target.value)} 
                            className={styles['radio-input']}
                        />
                        <span className={styles['radio-text']}>Female</span>
                    </label>
                    <label className={styles['radio-label']}>
                        <input 
                            type="radio" 
                            value="rather_not_say" 
                            checked={gender === 'rather_not_say'} 
                            onChange={(e) => setGender(e.target.value)} 
                            className={styles['radio-input']}
                        />
                        <span className={styles['radio-text']}>Rather Not Say</span>
                    </label>
                </div>
            </div>

            <div className={styles['input-row']}>
                
                <CountryPicker onChange={handleCountryChange} />
                <InputField 
                    label={'Phone Number'} 
                    type={'tel'} 
                    value={phone} 
                    onChange={setPhone} 
                    required={true} 
                />
            </div>

            <div className={styles['input-row']}>
                <InputField
                    label={'Create Password'}
                    type={'password'}
                    value={password}
                    onChange={setPassword}
                    required={true}
                />
                <InputField
                    label={'Confirm Password'}
                    type={'password'}
                    value={confirmPassword}
                    onChange={setConfirmPassword}
                    required={true}
                />
            </div>
            </div>
        
            <p className={styles['privacy-note']}>
                By registering, you confirm that you agree to the processing of your personal data by Zerone Analytiqs as described in the{' '}
                <Link to="/privacy-policy" className={styles['privacy-link']}>Privacy Statement</Link>.
            </p>
            <div className={styles['action-buttons']}>
                <PrimaryButton
                    text='Next'
                    primaryColor='#FF5522'
                    textColor='#FFFFFF'
                    hoverColor='#FFFFFF'
                    hoverTextColor='#FF5522'
                    doSomething={(event: React.FormEvent) => handleNextClicked(event)}
                />

                <SecondaryButton
                    text="Have an account? Login"
                    primaryColor="#FFFFFF"
                    textColor="#FF5522"
                    hoverColor="#FF5522"
                    hoverTextColor="#FFFFFF"
                    doSomething={() => handleLoginClicked()}
                />
            </div>          
            {validationErrors && validationErrors.length > 0 && (
                <span className={styles['error']}>{validationErrors[0]}</span>
            )}
        </form>
    );
};

export default SignUpForm;