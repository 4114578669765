import { EMAIL_SANITIZATION_REGEX, INTERNATIONAL_PHONE_REGEX, NAME_SANITIZATION_REGEX, PASSWORD_SANITIZATION_REGEX, PHONE_SANITIZATION_REGEX } from "constants/RegexConstants"
import CreateAccount from "interface/CreateAccount"

/**
 * Validates and sanitizes user input for creating an account.
 * 
 * @param {CreateAccount} accountData - The user input data for account creation.
 * @param {string} accountData.firstName - User's first name.
 * @param {string} accountData.lastName - User's last name.
 * @param {string} accountData.email - User's email address.
 * @param {string} accountData.gender - User's gender (e.g., "male", "female", "rather_not_say").
 * @param {string} accountData.country - User's country of residence.
 * @param {string} accountData.countryCode - User's ISO 3166-1 alpha-2 code
 * @param {string} accountData.phone - User's phone number (international format).
 * @param {string} accountData.password - User's account password.
 * @param {string} accountData.confirmPassword - User's confirmation of their account password.
 * 
 * @returns {{
*   isValid: boolean,
*   sanitizedFirstName: string,
*   sanitizedLastName: string,
*   sanitizedEmail: string,
*   sanitizedGender: string,
*   sanitizedCountry: string,
*   sanitizedCountryCode: string,
*   sanitizedPhone: string,
*   sanitizedPassword: string,
*   sanitizedConfirmPassword: string,
*   errors: string[]
* }} - Returns validation results, sanitized fields, and any errors.
* 
* @example
* const accountInput = {
*   firstName: " John ",
*   lastName: "Doe",
*   email: " john.doe@gmail.com ",
*   gender: "male",
*   country: "United States",
*   countryCode: "US",
*   phone: "+1234567890",
*   password: "Password123!",
*   confirmPassword: "Password123!"
* };
* 
* const result = validateAndSanitizeCreateAccount(accountInput);
* if (result.isValid) {
*   console.log("Sanitized Data:", result);
* } else {
*   console.error("Validation Errors:", result.errors);
* }
*/
export const validateAndSanitizeCreateAccount = ({
    firstName,
    lastName,
    email,
    gender,
    country,
    countryCode,
    phone,
    password,
    confirmPassword
} : CreateAccount) => {
    const errors: string[] = []

    // Required fields check
    if (!firstName.trim()) errors.push("First name is required.")
    if (!lastName.trim()) errors.push("Last name is required.")
    if (!email.trim()) errors.push("Email is required.")
    if (!gender.trim()) errors.push("Gender is required.")
    if (!country.trim()) errors.push("Country is required.")
    if (!countryCode || (typeof countryCode === 'string' && !countryCode.trim())) errors.push("Invalid country selected.")
    if (!phone.trim()) errors.push("Phone number is required.")
    if (!password.trim()) errors.push("Password is required.")
    if (!confirmPassword.trim()) errors.push("Confirm password is required.")
    
    const sanitizedFirstName = firstName.trim().toLowerCase().replace(NAME_SANITIZATION_REGEX, '')
    const sanitizedLastName = lastName.trim().toLowerCase().replace(NAME_SANITIZATION_REGEX, '')
    const sanitizedEmail = email.trim().toLowerCase().replace(EMAIL_SANITIZATION_REGEX, '')
    const sanitizedCountry = country.trim().toLowerCase().replace(NAME_SANITIZATION_REGEX, '')
    const sanitizedCountryCode = typeof countryCode === 'string'
    ? countryCode.trim().toLowerCase().replace(NAME_SANITIZATION_REGEX, '')
    : ''
    const sanitizedPhone = phone.trim().toLowerCase().replace(PHONE_SANITIZATION_REGEX, '')
    const sanitizedPassword = password.trim().replace(PASSWORD_SANITIZATION_REGEX, '')
    const sanitizedConfirmPassword = confirmPassword.trim().replace(PASSWORD_SANITIZATION_REGEX, '')

    const genderMapping: Record<string, string> = {
        male: 'Male',
        female: 'Female',
        rather_not_say: 'Rather not say'
    }

    const sanitizedGender = genderMapping[gender.toLowerCase()] || "Invalid"
    if (sanitizedGender === 'Invalid') {
        errors.push('Invalid gender selected.')
    }

    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(sanitizedEmail)) {
        errors.push("Invalid email format.")
    }

    // Password validation
    if (sanitizedPassword.length < 6) {
        errors.push('Password must be at least 6 characters long.')
    } else {
        const hasUpperCase = /[A-Z]/.test(sanitizedPassword);
        const hasLowerCase = /[a-z]/.test(sanitizedPassword);
        const hasNumber = /[0-9]/.test(sanitizedPassword);
        const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(sanitizedPassword);

        if (!hasUpperCase) {
            errors.push('Password must contain at least one uppercase letter.');
        }
        if (!hasLowerCase) {
            errors.push('Password must contain at least one lowercase letter.');
        }
        if (!hasNumber) {
            errors.push('Password must contain at least one number.');
        }
        if (!hasSpecialChar) {
            errors.push('Password must contain at least one special character.');
        }
    }

    if (sanitizedConfirmPassword !== password) {
         errors.push('Confirm password does not match provided password.')
    }

    if (!sanitizedPhone.startsWith('+')) {
        errors.push('Phone number, must start with +')
    } else if (sanitizedPhone.length < 8 || sanitizedPhone.length > 15) {
        errors.push("Invalid international phone number provided.")
    } else if (!sanitizedPhone.match(INTERNATIONAL_PHONE_REGEX)) {
        errors.push('Invalid international phone number provided.')
    }

    if (sanitizedCountry.toLowerCase() === 'select your country') {
        errors.push('Invalid country selected.')
    }

    if (sanitizedCountryCode.toLowerCase().length !== 2) {
        errors.push('Invalid country selected.')
    }

    return {
        isValid: errors.length === 0,
        sanitizedFirstName,
        sanitizedLastName,
        sanitizedEmail,
        sanitizedGender,
        sanitizedCountry,
        sanitizedCountryCode,
        sanitizedPhone,
        sanitizedPassword,
        sanitizedConfirmPassword,
        errors
    }
}