import { PriceCardProps } from 'interface/PriceCardProps';
import React from 'react'
import { Icon } from '@iconify/react'
import checkIcon from '@iconify/icons-mdi/check-bold'
import styles from 'styles/components/PriceCard.module.sass'
import SecondaryButton from 'components/common/SecondaryButton';

/**
 * PriceCard component renders a subscription card with the type, price, term, features, and a "Buy now" button.
 * 
 * @component
 * @param {string} subscriptionType - The type of subscription (e.g., 'basic', 'saphire', 'gold', 'platinum').
 * @param {string} moreInfo - Additional information about the subscription.
 * @param {string} price - The price of the subscription.
 * @param {string} subscriptionTerm - The term of the subscription (e.g., 'per month').
 * @param {() => void} onButtonClick - The function to be called when the "Buy now" button is clicked.
 * @param {string[]} features - An array of features included in the subscription.
 * 
 * @example
 * <PriceCard
 *   subscriptionType="basic"
 *   moreInfo="1 User Seat"
 *   price="GHS 1,000"
 *   subscriptionTerm="per month"
 *   onButtonClick={() => console.log('Buy now clicked')}
 *   features={[
 *     '1 Survey + Report',
 *     'Recorded trainings',
 *     'Non-customizable',
 *   ]}
 * />
 * 
 * @returns {JSX.Element} The JSX for the PriceCard component.
 */
const PriceCard: React.FC<PriceCardProps> = ({
    subscriptionType,
    moreInfo,
    price,
    discount,
    subscriptionTerm,
    onButtonClick,
    features
}) => {
    // If error on price fall back to mailto:zeroneanalytiqs.
    const handleButtonClick = () => {
        if (price === 'Contact us') {
            window.location.href = 'mailto:info@zeroneanalytiqs.com';
        } else {
            onButtonClick();
        }
    };

    return (
        <div className={styles['price-card']}>
            <div className={styles['subscription-type-container']}>
                <p className={`${styles['subscription-type']} ${styles[subscriptionType]}`}>{subscriptionType.toUpperCase()}</p>
                <p className={styles['more-info']}>{moreInfo}</p>
            </div>
            <div className={styles['price-container']}>          
                <div className={styles['price-change-container']}>
                    <span className={styles['old-price']}>{price}</span>
                    <span className={styles['price']}>{discount}</span>
                </div>
                <span className={styles['term']}>{subscriptionTerm}</span>
            </div>
            {/* eslint-disable @typescript-eslint/no-unsafe-assignment */}
                <SecondaryButton
                    width='100%'
                    text="Buy now"
                    primaryColor="#FFFFFF"
                    textColor="#ff5522"
                    hoverColor="#ff5522"
                    hoverTextColor="#FFFFFF"
                    doSomething={handleButtonClick}
                />
            {/* eslint-enable @typescript-eslint/no-unsafe-assignment */}
            <ul className={styles['feature-list']}>
                {features.map((feature: string, index: number) => (
                    <li key={index} className={styles['feature-item']}>
                        <Icon icon={checkIcon} className={styles['check-icon']} />
                        {feature}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default PriceCard;