import endpoints from "constants/api/Endpoints"
import { AfricasDigitizationFootPrint } from "constants/data/report/AfricasDigitizationFootPrint"
import { zeroneReportCategories } from "constants/data/report/ZeroneReportCategories"
import { CategoryTopic } from "interface/CategoryTopic"
import FetchDataByIDProps from "interface/FetchDataByIDProps"
import { ReportCategory } from "interface/ReportCategory"
import { ReportContent } from "interface/ReportContent"
import { logError } from "services/logService/errorLogger"

/**
 * Fetches report categories from the API or returns default categories if an error occurs.
 * 
 * @async
 * @function fetchCategories
 * @param {string} tag - A tag used for logging and identifying the request.
 * @returns {Promise<ReportCategory[]>} - A promise that resolves to an array of report categories.
 * 
 * @example
 * async function loadCategories() {
 *   const categories = await fetchCategories('CategoryFetch');
 *   console.log('Fetched categories:', categories);
 * }
 */
export async function fetchCategories(tag: string): Promise<ReportCategory[]>{
    try {
        const result = await endpoints.getReportCategories({ tag })
        if (result.error) {
            logError('Error fetching report categories.', { result }, tag)
            return defaultCategories()
        }
        return result.data
    } catch (error) {
        logError(
            'Unable to retrieve report categories.',
            { error: error instanceof Error ? error.message : 'Unknown error' },
            tag
        )
        return defaultCategories()
    }
}

/**
 * Fetches top hot reports (trending reports) from the API or returns default trending reports if an error occurs.
 * 
 * @async
 * @function fetchTopHotReports
 * @param {string} tag - A tag used for logging and identifying the request.
 * @returns {Promise<CategoryTopic[]>} - A promise that resolves to an array of trending reports.
 * 
 * @example
 * async function loadHotReports() {
 *   const hotReports = await fetchTopHotReports('HotReportFetch');
 *   console.log('Fetched trending reports:', hotReports);
 * }
 */
export async function fetchTopHotReports(tag: string): Promise<CategoryTopic[]>{
    try {
        const result = await endpoints.getTopHotReports({ tag })
        if (result.error) {
            logError('Error fetching trending reports.', { result }, tag)
            return defaultTrendingReports()
        }
        return result.data
    } catch (error) {
        logError(
            'Unable to retrieve trending reports.',
            { error: error  instanceof Error ? error.message : 'Unknown error' },
            tag
        )
        return defaultTrendingReports()
    } 
}

/**
 * Fetches a specific report by its ID from the API or returns a default report if an error occurs.
 * 
 * @async
 * @function fetchReport
 * @param {FetchDataByIDProps} params - An object containing the tag for logging and the ID of the report to fetch.
 * @returns {Promise<ReportContent | null>} - A promise that resolves to the fetched report content or `null` if not found.
 * 
 * @example
 * async function loadReport() {
 *   const report = await fetchReport({ tag: 'ReportFetch', id: '12345' });
 *   console.log('Fetched report:', report);
 * }
 */
export async function fetchReport({ tag, id }: FetchDataByIDProps): Promise<ReportContent | null> {
    if (!id) {
        logError('Invalid ID provided', { id }, tag)
        return null
    }
    try {
        const result = await endpoints.getReport({ tag, id })
        if (result.error || result.data === null) {
            logError('Error fetching report.', { id, result }, tag)
            return defaultReport()
        }
        return result.data
    } catch (error) {
        logError(
            'Unable to retrieve report.',
            { id, error: error instanceof Error ? error.message : 'Unknown error' },
            tag
        )
        return defaultReport()
    }
}

/**
 * Provides a default set of report categories for use when fetching categories fails.
 * 
 * @function defaultCategories
 * @returns {ReportCategory[]} - An array of default report categories.
 */
function defaultCategories(): ReportCategory[] {
    return zeroneReportCategories;
}

/**
 * Provides a default set of trending reports for use when fetching hot reports fails.
 * 
 * @function defaultTrendingReports
 * @returns {CategoryTopic[]} - An array of default trending reports.
 */
function defaultTrendingReports(): CategoryTopic[] {
    return [
        {
            id: "05c1ba4d-552d-4dc1-bee0-eba6b68a7cc4",
            title: "GHANA'S DOMESTIC AIRLINE MARKET",
            description: "In this report we present analysis, findings and insights on the domestic commercial airline market in Ghana. It contains a detailed review of the competitive landscape including who the major players are, market shares and competition strategies. We project key metrics such as revenue passenger miles (RPM), Available Seat Miles (ASM), Passenger load factor, etc. The report also contains customer preference, insights and sentiments on specific airlines and the industry as a whole.",
            dataset: "Markets, Industries & Sectors",
            year: "2024",
            meta: {
                cover: 'https://firebasestorage.googleapis.com/v0/b/zeroneanalytics-55fec.appspot.com/o/reports%2FGHANAS%20DOMESTIC%20AIRLINE%20MARKET%2Fdomestic-airline.jpg?alt=media&token=ca757880-9663-4801-b40a-100852be2120'
            },
            category: ""
        },
        {
            id: "5740dba9-85e5-4286-9f00-0307ed841b51",
            title: "SHORT-STAY RENTAL IN KENYA",
            description: "Discover how players in Kenya’s short-term hospitability sector are pricing their units and what features have the biggest sway on guests. This report contains analysis of several Airbnb listings in Kenya in 2024. It assesses which neighborhoods command premium pricing, the effects of guest ratings/reviews on success and analyzes the impact of hosts gender, etc.",
            dataset: "Countries & Economies",
            year: "2024",
            meta: {
                cover: 'https://firebasestorage.googleapis.com/v0/b/zeroneanalytics-55fec.appspot.com/o/reports%2FSHORT-STAY%20RENTAL%20IN%20KENYA%2Fshort-stay-rental-kenya.jpg?alt=media&token=13260ce0-a94c-45df-a995-60e27d78eb99'
            },
            category: ""
        },
        {
            id: "d6c92e98-25a8-491a-aeb3-4dd247bd18e3",
            title: "AFRICA'S DIGITIZATION FOOTPRINT",
            description: 'Dive into our "State of Data In Africa" report and discover how the global digital revolution is playing out in the African context. This report contains an analysis of Africa’s data landscape, including the rise in data centers, government spending on digitization and private sector initiatives, among others. It discusses data gaps in African and opportunities available in the market for investors, entrepreneurs and other players.',
            dataset: "Markets, Industries & Sectors",
            year: "2024",
            meta: {
                cover: 'https://firebasestorage.googleapis.com/v0/b/zeroneanalytics-55fec.appspot.com/o/reports%2FAFRICAS%20DIGITIZATION%20FOOTPRINT%2Fafrica-digitization.jpg?alt=media&token=b8581cbd-e77f-42a3-8ed6-a75d622b4622'
            },
            category: ""
        }
    ]
}

function defaultReport(): ReportContent {
    return AfricasDigitizationFootPrint;
}