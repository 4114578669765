
/* eslint-disable @typescript-eslint/no-redundant-type-constituents */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-argument */

import endpoints from "constants/api/Endpoints";
import EndpointProps from "interface/EndpointProps";
import SignUpRequest from "constants/api/models/SignUpRequest";
import { SignUpData } from "constants/api/models/SignUpResponse";
import { logError } from "services/logService/errorLogger";

/**
 * Processes user signup by handling more complex scenarios, such as
 * server response validation and detailed error handling.
 * 
 * @async
 * @function processSignUpUser
 * @param {EndpointProps & { data: SignUpRequest }} params - The request parameters containing a unique tag and signup payload.
 * @returns {Promise<{ data: SignUpData } | { error: string | null }}> - A promise that resolves to an object containing the signup response data or an error message.
 */
export async function processSignUpUser({
    tag,
    data,
}: EndpointProps & { data: SignUpRequest }): Promise< { data: SignUpData } | { error: string | null }> {
    console.log(data)
    if (!data) {
        return {
            error: "Please provide all required details to create an account.",
        };
    }

    try {
        const result = await endpoints.signUpUser({ tag, data });

        if (result.error) {
            logError("Error creating user account.", { result }, tag);

            if (typeof result.error === "string") {
                const serverError = result.error.toLowerCase();
                console.log(serverError)
                if (serverError.includes("duplicate") || serverError.includes('exists')) {
                    return {
                        error: "An account with this email or phone number already exists. Please log in or use a different email.",
                    };
                }
                if (serverError.includes("validation")) {
                    return {
                        error: "Some required fields are missing or invalid. Please check your details and try again.",
                    };
                }
                return {
                    error: "We encountered an issue while processing your request. Please try again.",
                };
            }

            return {
                error: "An unexpected error occurred while creating your account. Please try again later.",
            };
        }

        const defaultResponse: SignUpData = {} as SignUpData
        const formattedResponse: SignUpData = (result.data as unknown as SignUpData) ?? defaultResponse

        return { data: formattedResponse }
    } catch (error) {
        logError(
            "Unexpected error during signup.",
            { error: error instanceof Error ? error.message : "Unknown error" },
            tag
        );
        return {
            error: "We are unable to process your signup at the moment. Please check your connection and try again later.",
        };
    }
}

export async function processLoginUser({
    tag,
    data
}: EndpointProps & { data: { email: string; password: string; } }): Promise< { success: boolean } | { error: string | null }> {
    if (!data) {
        return {
            error: "Please provide all required details to log in to your account."
        }
    }

    try {
        const result = await endpoints.logInUser({ tag, data })

        if (result.error) {
            logError('Error authenticating account.', { result }, tag)

            if (typeof result.error === 'string') {
                const serverError = result.error.toLowerCase()
                if (serverError.includes('not found')) {
                    return {
                        error: 'Account not found. Please verify the email address.'
                    }
                } else if (serverError.includes('incorrect')) { 
                    return { 
                        error: 'Incorrect email or password. Please try again.'
                    }
                } else if (serverError.includes("validation")) {
                    return {
                        error: "Some required fields are missing or invalid. Please check your details and try again."
                    }
                }
                return {
                    error: 'We encountered an issue while processing your request. Please try again.'
                }
            }

            return {
                error: 'An unexpected error occurred while authenticating your account. Please try again later.'
            }
        }

        return { 
            success: result.success
        }

    } catch (error) {
        logError(
            'Unexpected error during login.',
            { error: error instanceof Error ? error.message : 'Unknown error' },
            tag
        )
        return {
            error: 'We are unable to process your login request at the moment. Please check your connection and try again later.'
        }
    }
}

/* eslint-enable @typescript-eslint/no-redundant-type-constituents */
/* eslint-enable @typescript-eslint/no-unsafe-return */
/* eslint-enable @typescript-eslint/no-unsafe-argument */

export default processSignUpUser;