import React from 'react'
import styles from 'styles/components/ZeroneLoader.module.sass'
import zeroneIconAnimation from 'assets/animations/animated_zerone_icon.json'
import Lottie from 'lottie-react';

interface ZeroneLoaderProps {
    text?: string;
}

/**
 * ZeroneLoader component displays a loading animation with optional text.
 * 
 * @component
 * 
 * @param {string} [text='Processing'] - The loading text displayed below the animation. Defaults to 'Processing'.
 * 
 * @example
 * // Basic usage
 * <ZeroneLoader />
 * 
 * @example
 * // Usage with custom text
 * <ZeroneLoader text="Loading your data..." />
 * 
 * @returns {JSX.Element} A loader with an animated icon and optional text.
 */
const ZeroneLoader: React.FC<ZeroneLoaderProps> = ({ text = 'Processing' }) => {
    return (
        <div className={styles['zerone-loader']}>
            <Lottie
                animationData={zeroneIconAnimation}
                loop
                className={styles['lottie-animation']}/>
            <span className={styles['loading-text']}>{text}</span>
        </div>
    )
}

export default ZeroneLoader;