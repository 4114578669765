import { logError } from "services/logService/errorLogger";
import { verifySignature } from "utils/VerifyGetSingatureUtil";
import { SignedApiResponse } from "./ApiTypes";

/**
 * Fetches data from an API call and processes it using a data extractor function.
 * 
 * @template T - The type of data returned by the API response.
 * @param {string} TAG - A unique tag for logging and debugging purposes.
 * @param {() => Promise<SignedApiResponse<T>>} apiCall - A function that performs the API call and returns a promise resolving to a SignedApiResponse object.
 * @param {(data: T) => any} dataExtractor - A function that extracts the desired data from the response.
 * @returns {Promise<SignedApiResponse<any>>} A promise resolving to the extracted data, an optional signature, and any error message.
 * 
 * @example
 * async function exampleUsage() {
 *   const apiCall = async (): Promise<SignedApiResponse<{ name: string }>> => ({
 *     response: {
 *       status: "success",
 *       message: "Data fetched.",
 *       data: { name: "Pricing" },
 *       timestamp: new Date().toISOString(),
 *     },
 *     signature: "abc123signature",
 *   });
 *   
 *   const dataExtractor = (data: { name: string }) => data.name;
 *   
 *   const result = await fetchApiData('PricingPage', apiCall, dataExtractor);
 *   console.log(result); // { response: { status: 'success', message: 'Data fetched.', data: 'Pricing', timestamp: ... }, signature: 'abc123signature' }
 * }
 */
export async function fetchApiData<T>(
    TAG: string,
    apiCall: () => Promise<SignedApiResponse<T>>,
    dataExtractor: (data: T) => any
): Promise<SignedApiResponse<any>> {
    try {
        const { response, signature } = await apiCall();

        if (response.status === "error") {
            return {
                response: {
                    status: "error",
                    message: response.message ?? "An unknown error occurred.",
                    error: response.message ?? "Unknown error.",
                    timestamp: response.timestamp,
                },
                signature: signature ?? null,
            };
        }

        return {
            response: {
                status: "success",
                message: response.message ?? "Request successful.",
                data: response.data ? dataExtractor(response.data) : null,
                timestamp: response.timestamp,
            },
            signature: signature ?? null,
        };
    } catch (error) {
        const errorMessage = error instanceof Error ? error.message : "Unknown error";
        logError("Error fetching data.", { error: errorMessage }, TAG);

        return {
            response: {
                status: "error",
                message: "Failed to fetch data.",
                error: errorMessage,
                timestamp: new Date().toISOString(),
            },
            signature: null,
        };
    }
}


/**
 * Interface for representing properties required for verifying a GET request.
 * 
 * @interface GetRequestProps
 * @property {string} tag - A string representing a unique tag for logging or identification purposes.
 * @property {string} url - The URL of the GET request being verified.
 * @property {object | string | null} data - The data returned by the GET request, which will be verified.
 * @property {string} signature - The cryptographic signature to be verified.
 * 
 * @example
 * const props: GetRequestProps = {
 *   tag: 'FetchUserDetails',
 *   url: 'https://api.zerone.com/blog/',
 *   data: { title: 123, category: 'Blog A' },
 *   signature: 'abcdef1234567890'
 * };
 */
interface GetRequestProps {
    tag: string;
    url: string;
    data: object | string | null,
    signature: string
}

/**
 * Verifies the signature for GET request data.
 *
 * @function
 * @param {GetRequestProps} props - Contains tag, URL, data, and signature for verification.
 * @returns {SignedApiResponse<any>} - The response object indicating success or failure of signature verification.
 * 
 * @example
 * const props: GetRequestProps = {
 *   tag: "FetchUserDetails",
 *   url: "https://api.zerone.com/blog/",
 *   data: { title: "Blog A", category: "Tech" },
 *   signature: "abcdef1234567890",
 * };
 * 
 * const verificationResult = verifyGetRequest(props);
 * if (verificationResult.response.status === "success") {
 *   console.log("Signature is valid.");
 * } else {
 *   console.error("Signature verification failed:", verificationResult.response.error);
 * }
 */
export function verifyGetRequest({
    tag,
    url,
    data,
    signature,
}: GetRequestProps): SignedApiResponse<any> {
    if (!data || !signature) {
        logError("Missing data or signature for verification", { data, url }, tag);

        return {
            response: {
                status: "error",
                message: "Verification failed.",
                error: "Data and signature are required for verification.",
                timestamp: new Date().toISOString(),
            },
            signature: null,
        };
    }

    if (typeof data === "string" || typeof data === "object") {
        const isValidSignature = verifySignature(data, signature, tag);

        if (!isValidSignature) {
            logError("Invalid signature detected", { data, signature, url }, tag);

            return {
                response: {
                    status: "error",
                    message: "Verification failed.",
                    error: "Invalid signature detected.",
                    timestamp: new Date().toISOString(),
                },
                signature: null,
            };
        }
    } else {
        logError("Invalid data type for signature verification", { data, url }, tag);

        return {
            response: {
                status: "error",
                message: "Verification failed.",
                error: "Invalid data type for verification.",
                timestamp: new Date().toISOString(),
            },
            signature: null,
        };
    }

    return {
        response: {
            status: "success",
            message: "Signature verified successfully.",
            data,
            timestamp: new Date().toISOString(),
        },
        signature,
    };
}