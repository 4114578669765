import EndpointProps from "interface/EndpointProps"
import { fetchApiData } from "../ApiHelper"
import { get } from "../ApiClient"
import { ReportCategory } from "interface/ReportCategory"
import { CategoryTopic } from "interface/CategoryTopic";
import FetchDataByIDProps from "interface/FetchDataByIDProps";
import { ReportContent } from "interface/ReportContent";

/**
 * Fetches a list of report categories from the server.
 * 
 * This function performs an API call to retrieve report categories using the provided tag and
 * processes the API response using the `fetchApiData` utility.
 * 
 * @async
 * @function getReportCategories
 * @param {EndpointProps} params - The request parameters containing a unique tag for identifying the request.
 * @returns {Promise<{ data: ReportCategory[]; error: string | null }>} - A promise that resolves to an object containing the array of `ReportCategory` and any error message.
 * 
 * @example
 * // Example usage:
 * async function loadReportCategories() {
 *   const result = await getReportCategories({ tag: 'ReportFetch' });
 *   if (result.error) {
 *     console.error('Error fetching report categories:', result.error);
 *   } else {
 *     console.log('Fetched report categories:', result.data);
 *   }
 * }
 * // Sample result structure:
 * {
 *   data: [
 *       {
 *           id: "category1",
 *           title: "Market Analysis",
 *           meta: {
 *               icon: "mdi:chart-line",
 *               primaryColor: "#123456",
 *               secondaryColor: "#abcdef"
 *           },
 *           description: "In-depth market analysis across sectors.",
 *           topics: [
 *               { id: "topic1", title: "Consumer Trends", year: "2024" },
 *               { id: "topic2", title: "Market Share", year: "2023" }
 *           ]
 *       },
 *       {
 *           id: "category2",
 *           title: "Technology Innovations",
 *           meta: {
 *               icon: "mdi:lightbulb-on",
 *               primaryColor: "#654321",
 *               secondaryColor: "#fedcba"
 *           },
 *           description: "Discover the latest technology innovations.",
 *           topics: [
 *               { id: "topic3", title: "AI in Healthcare", year: "2024" },
 *               { id: "topic4", title: "Blockchain Applications", year: "2023" }
 *           ]
 *       }
 *   ],
 *   error: null
 * }
 */
export async function getReportCategories({ tag }: EndpointProps): Promise<{ data: ReportCategory[]; error: string | null }> {
    const result = await fetchApiData<{ categories: ReportCategory[] }>(
        tag,
        () => get<{ categories: ReportCategory[] }>({ tag, url: "report/categories" }),
        (data) => data.categories || []
    );

    return {
        data: result.response.status === "success" ? result.response.data : [],
        error: result.response.status === "error" ? result.response.error ?? null : null,
    };
}

/**
 * Fetches a list of top hot reports from the server.
 * 
 * This function performs an API call to retrieve top hot reports using the provided tag and
 * processes the API response using the `fetchApiData` utility.
 * 
 * @async
 * @function getTopHotReports
 * @param {EndpointProps} params - The request parameters containing a unique tag for identifying the request.
 * @returns {Promise<{ data: CategoryTopic[]; error: string | null }>} - A promise that resolves to an object containing the array of `CategoryTopic` and any error message.
 * 
 * @example
 * // Example usage:
 * async function loadTopHotReports() {
 *   const result = await getTopHotReports({ tag: 'TrendingReportsFetch' });
 *   if (result.error) {
 *     console.error('Error fetching top hot reports:', result.error);
 *   } else {
 *     console.log('Fetched top hot reports:', result.data);
 *   }
 * }
 * // Sample result structure:
 * {
 *   data: [
 *       {
 *           id: "report1",
 *           title: "2024 Elections in Ghana",
 *           description: "A comprehensive analysis of the upcoming elections.",
 *           dataset: "Politics",
 *           year: "2024",
 *           meta: {
 *               cover: "https://example.com/image.jpg"
 *           }
 *       },
 *       {
 *           id: "report2",
 *           title: "Beverage Consumption Trends",
 *           description: "Trends in beverage consumption in 2024.",
 *           dataset: "Drinks & Beverages",
 *           year: "2024",
 *           meta: {
 *               cover: "https://example.com/beverages.jpg"
 *           }
 *       }
 *   ],
 *   error: null
 * }
 */
export async function getTopHotReports({ tag }: EndpointProps): Promise<{ data: CategoryTopic[]; error: string | null }> {
    const result = await fetchApiData<{ reports: CategoryTopic[] }>(
        tag,
        () => get<{ reports: CategoryTopic[] }>({ tag, url: "report/top-hot-reports" }),
        (data) => data.reports || []
    );

    return {
        data: result.response.status === "success" ? result.response.data : [],
        error: result.response.status === "error" ? result.response.error ?? null : null,
    };
}

/**
 * Fetches a specific report by ID from the server.
 * 
 * This function performs an API call to retrieve a report based on its unique ID and
 * processes the API response using the `fetchApiData` utility.
 * 
 * @async
 * @function getReport
 * @param {FetchDataByIDProps} params - The request parameters containing a tag and the report's unique ID.
 * @returns {Promise<{ data: ReportContent | null; error: string | null }>} - A promise that resolves to an object containing the report data or an error message.
 * 
 * @example
 * // Example usage:
 * async function loadReportById() {
 *   const result = await getReport({ tag: 'ReportFetch', id: '12345' });
 *   if (result.error) {
 *     console.error('Error fetching report:', result.error);
 *   } else {
 *     console.log('Fetched report:', result.data);
 *   }
 * }
 * // Sample result structure:
 * {
 *   data: {
 *       id: "report1",
 *       title: "2024 Elections in Ghana",
 *       description: "A comprehensive analysis of the upcoming elections.",
 *       dataset: "Politics",
 *       year: "2024",
 *       meta: {
 *           cover: "https://example.com/image.jpg"
 *       }
 *   },
 *   error: null
 * }
 */
export async function getReport({ tag, id }: FetchDataByIDProps): Promise<{ data: ReportContent | null; error: string | null }> {
    const result = await fetchApiData<{ report: ReportContent }>(
        tag,
        () => get<{ report: ReportContent }>({ tag, url: `report/${id}` }),
        (data) => data.report || null
    );

    return {
        data: result.response.status === "success" ? result.response.data : null,
        error: result.response.status === "error" ? result.response.error ?? null : null,
    };
}