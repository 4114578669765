import { logError } from 'services/logService/errorLogger'
import CryptoJS from 'crypto-js';

/**
 * Verifies the cryptographic HMAC signature of a given JSON object or string using a secret key.
 *
 * @param {object | string} data - The data to verify. It must be a non-null object or string.
 * @param {string} providedSignature - The signature provided by the server to verify against.
 * @param {string} [TAG='VerifyPostSignature'] - An optional tag (file calling this method) for logging errors.
 * @returns {boolean} True if the signature is valid, false otherwise.
 * 
 * @example
 * // Example usage:
 * const responseData = { id: 123, name: 'Report' };
 * const serverProvidedSignature = 'abc123';
 * 
 * const isValidSignature = verifySignature(responseData, serverProvidedSignature);
 * if (isValidSignature) {
 *     console.log('Signature is valid.');
 * } else {
 *     console.log('Signature verification failed.');
 * }
 */
export const verifySignature = (data: object | string, providedSignature: string, TAG: string = 'VerifyPostSignature'): boolean => {
    const secret = process.env.REACT_APP_GET_SIGNATURE_SECRET;
    if (!secret) {
        logError('SIGNATURE_SECRET is missing.', {}, TAG);
        return false;
    }

    if ((typeof data !== 'object' && typeof data !== 'string') || data === null) {
        logError('Invalid data: Input must be a non-null object or string.', { data }, TAG);
        return false;
    }

    try {
        const sortedData = typeof data === 'object' ? JSON.stringify(data, Object.keys(data).sort()) : data;
        const hmac = CryptoJS.HmacSHA256(sortedData, secret);
        const generatedSignature = hmac.toString(CryptoJS.enc.Hex);

        return generatedSignature === providedSignature;
    } catch (error) {
        logError(
            'Error verifying GET/ POST signature',
            { error: error instanceof Error ? error.message : 'Unknown error' },
            TAG
        );
        return false;
    }
}