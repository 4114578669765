/* eslint-disable @typescript-eslint/no-redundant-type-constituents */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import endpoints from "constants/api/Endpoints";
import { SignUpData } from "constants/api/models/SignUpResponse";
import ChangePlan from "interface/ChangePlan";
import EndpointProps from "interface/EndpointProps";
import UserSubscription from "interface/UserSubscription";
import { logError } from "services/logService/errorLogger";

const fileName = 'SubscriptionApiService'

export async function processChangePlan({
    tag,
    data,
}: EndpointProps & { data: ChangePlan }): Promise< { data: SignUpData } | { error: string | null }> {
    if (!data) {
        return {
            error: "Please provide all required details to update your account.",
        };
    }

    try {
        const result = await endpoints.updateSubscriptionPlan({ tag, data });

        if (result.error) {
            logError("Error creating user account.", { result }, tag);

            if (typeof result.error === "string") {
                const serverError = result.error.toLowerCase();
                if (serverError.includes("validation")) {
                    return {
                        error: "Some required fields are missing or invalid. Please check your details and try again. You can also update your details in your profile..",
                    };
                }
                return {
                    error: "We encountered an issue while processing your request. Please try again. You can still log in and manage your profile.",
                };
            }

            return {
                error: "An unexpected error occurred while processing your request. Please try again later. You can log in and update your profile in the meantime.",
            };
        }

        const defaultResponse: SignUpData = {} as SignUpData
        const formattedResponse: SignUpData = (result.data as unknown as SignUpData) ?? defaultResponse

        return { data: formattedResponse }
    } catch (error) {
        logError(
            "Unexpected error during signup.",
            { error: error instanceof Error ? error.message : "Unknown error" },
            tag
        );
        return {
            error: "We are unable to process your request at the moment. Please check your connection and try again later. You can still log in and update your profile.",
        };
    }
}

export const fetchUserSubscription = async (): Promise<UserSubscription | null> => {
    try {
        const result = await endpoints.fetchSubscriptionData({ tag: fileName })
        if (result.success && result.data) {
            return result.data
        }
        return null
    } catch (error) {
        logError(
            'Error retrieving subscription.',
            { error },
            fileName
        )
        return null
    }
}

/* eslint-enable @typescript-eslint/no-redundant-type-constituents */
/* eslint-enable @typescript-eslint/no-unsafe-return */
/* eslint-enable @typescript-eslint/no-unsafe-argument */