import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styles from 'styles/components/IndividualBusinessPricing.module.sass'
import { formatCurrency } from 'utils/formatCurrency'
import PriceCard from 'components/cards/PriceCard'
import CurrencySelector from 'components/common/CurrencySelector'
import { basicPricePlan } from 'constants/subscription/BasicPricePlan'
import { saphirePricePlan } from 'constants/subscription/SaphirePricePlan'
import { goldPricePlan } from 'constants/subscription/GoldPricePlan'
import { platinumPricePlan } from 'constants/subscription/PlatinumPricePlan'
import SecondaryButton from 'components/common/SecondaryButton'
import arrowRightLight from '@iconify/icons-ph/arrow-right-light';

interface IndividualBusinessPricingProps {
    showCompareButton?: boolean;
}

/**
 * IndividualBusinessPricing component displays a grid of pricing plans for individual and business users,
 * allowing selection of different currencies and comparing plan features.
 * 
 * @component
 * 
 * @example
 * // Renders the IndividualBusinessPricing component with a currency selector and pricing plans
 * <IndividualBusinessPricing />
 * 
 * @see CurrencySelector - Component for selecting the currency type (African or Non-African)
 * @see PriceCard - Component for displaying individual pricing plan details
 */
const IndividualBusinessPricing: React.FC<IndividualBusinessPricingProps> = ({ showCompareButton = true }) => {
    const [selectedCurrency, setSelectedCurrency] = useState('Non-African')
    const navigate = useNavigate()

    const indivBussPricingList = [
        basicPricePlan,
        saphirePricePlan,
        goldPricePlan,
        platinumPricePlan
    ]

    function handleButtonClick(): void {
        navigate('/pricing')
        window.scrollTo(0, 0)
    }

    return (
        
        <div className={styles['individual-business-containeer']}>
            <CurrencySelector
                availableCurrencies={['African', 'Non-African']}
                selectedCurrency={selectedCurrency}
                setSelectedCurrency={setSelectedCurrency}
            />
            <div className={styles['price-plan-grid']}>
                    {indivBussPricingList.map((plan) => (
                        <PriceCard
                            key={plan.subscriptionType}
                            subscriptionType={plan.subscriptionType}
                            moreInfo={plan.moreInfo}
                            price={formatCurrency(
                                'USD',
                                parseFloat(
                                    selectedCurrency === 'Non-African' 
                                        ? String(plan.nonAfricanPrice ?? '0')
                                        : String(plan.price ?? '0')
                                )
                            )}
                            discount={`${(parseFloat(
                                    selectedCurrency === 'Non-African' 
                                        ? String(plan.nonAfricanPrice ?? '0')
                                        : String(plan.price ?? '0')
                            ) / 2).toFixed(2)}`}
                            subscriptionTerm={plan.subscriptionTerm}
                            onButtonClick={plan.onButtonClick}
                            features={plan.features}
                        />
                    ))}
            </div>
            
            {showCompareButton && (
                <SecondaryButton
                    width='auto'
                    text="Compare plans"
                    border='none'
                    primaryColor="#fbfbfb"
                    textColor="#ff5522"
                    hoverColor="#fbfbfb"
                    hoverTextColor="#ff5522"
                    icon={arrowRightLight}
                    doSomething={handleButtonClick}
                />
            )}
        </div>
    )
}

export default IndividualBusinessPricing