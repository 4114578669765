import countryDetails from 'constants/data/global/CountryData';
import CountryValue from 'interface/CountryValue';
import React, { useEffect, useState } from 'react';
import styles from 'styles/components/CountryPicker.module.sass'
import FlagSelect from 'react-flags-select';
import getDefaultCountryFromLocale from 'utils/DefaultCountryFromLocale';

interface CountryPickerProps {
    onChange: (country: CountryValue) => void;
}

/**
 * CountryPicker component renders a dropdown for selecting a country with its flag.
 *
 * @component
 * @param {CountryPickerProps} props - The properties for the CountryPicker component.
 * @param {(country: CountryValue) => void} props.onChange -
 * A callback function triggered when a country is selected.
 */
const CountryPicker: React.FC<CountryPickerProps> = ({ onChange }) => {
    // State to manage the selected country
    const [selected, setSelected] = useState<string>('Select your country');

    /**
     * Handles the country selection change.
     *
     * @param {string} isoCode - The ISO code of the selected country.
     */
    const handleChange = (isoCode: string) => {
         // Update the selected country in state
        setSelected(isoCode)

        const selectedCountry = countryDetails[isoCode]
        if (selectedCountry) {
            onChange(selectedCountry)
        } else {
            console.warn(`No country found for ISO code: ${isoCode}`)
        }
    };

    useEffect(() => {
        const defaultCountryISO = getDefaultCountryFromLocale();
        if (defaultCountryISO) {
            handleChange(defaultCountryISO);
        }
    }, []); 

    return (
        <div className={styles['country-picker-container']}>
            <label className={styles['label']}>
                Country/Region<span className={styles['required']}>*</span>
            </label>
            <FlagSelect
                selected={selected}
                searchable
                onSelect={handleChange}
                placeholder="Select your country"
                className={styles['flag-select'] || 'flag-select'}
                alignOptionsToRight={false}
            />
        </div>
    );
};

export default CountryPicker;
