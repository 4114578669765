import endpoints from "constants/api/Endpoints";
import UpdatePayment from "interface/UpdatePayment";
import { logError } from "services/logService/errorLogger";

export const processSubscriptionResponse = async (params: UpdatePayment): Promise<boolean> => {
    try {
        if (!params) {
            logError('Missing payment payload.', params, 'PaymentApiService')
            return false
        }
        
        const result = await endpoints.updateSubscriptionPayment({ tag: 'PaymentApiService', data: params })
        return result
    } catch (error) {
        logError(
            'Error updating payment response',
            { error, params },
            'PaymentApiService'
        )
        return false
    }
}