import React, { useEffect } from 'react';
import Lottie from 'lottie-react';
import successAnimation from 'assets/animations/SuccessAnimation.json';
import errorAnimation from 'assets/animations/ErrorAnimation.json'
import styles from 'styles/components/SuccessErrorSection.module.sass';
import { useLocation, useNavigate } from 'react-router-dom';

/**
 * Props for the SuccessErrorSection component.
 *
 * @interface SuccessErrorSectionProps
 * @property {string} [title] - The title for the message.
 * @property {string} [message] - The main message to display.
 * @property {string} [note] - An additional note to provide context.
 * @property {string} [redirectTo] - The route to redirect the user to after the process.
 * @property {'success' | 'error'} messageType - Type of the message (success or error).
 * @property {Record<string, any>} [meta] - Additional metadata for the message.
 */
export interface SuccessErrorSectionProps {
  title: string;
  message: string;
  note?: string;
  redirectTo?: string;
  messageType: 'success' | 'error';
  meta?: Record<string, any>
}

/**
 * SuccessErrorSection component dynamically displays a success or error message with an animation.
 *
 * @component
 * @example
 * <SuccessErrorSection
 *   title="Check your email to verify your account."
 *   message="Thank you! Your payment was received, and we're finalizing your account setup."
 *   note="If you didn't see the email in your inbox, please check your spam folder."
 *   redirectTo="/dashboard"
 *   messageType="success"
 * />
 */
const SuccessErrorSection: React.FC = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const {
    title = 'All set!',
    message = `We've handled everything. You're ready to proceed.`,
    note = 'We will redirect you in just a moment.',
    redirectTo = '/',
    messageType = 'success',
    meta = {}
  } = location.state || {} as SuccessErrorSectionProps

  const animationData = 
    messageType === 'success' ? successAnimation
      : errorAnimation

      // Auto-redirect after 5 seconds
      useEffect(() => {
        const timeout = setTimeout(() => {
          navigate( redirectTo as string || '/', { state: { meta }})
        }, 5000)

        return () => clearTimeout(timeout)
      }, [redirectTo, navigate])

      const handleRedirect = () => {
        navigate(redirectTo as string || '/', { state: { meta } });
      };

  return (
    <div className={styles.successSection}>
      <Lottie animationData={animationData} loop={true} className={styles.animation} />
        <h2 className={styles.title}>{title}</h2>
        <p className={styles.subtitle}>{message}</p>
      <div className={styles['footer']}>
        <p className={styles.note}>{note}</p>
        <a 
          className={styles['redirect']}
          onClick={handleRedirect}
          role='button'
          tabIndex={0}
          onKeyDown={(e) => e.key === 'Enter' && handleRedirect}>
            {`Not redirected automatically?`}
          </a>
        </div>
    </div>
  );
};

export default SuccessErrorSection;
